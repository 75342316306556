import { feeActionType } from './fees.actionTypes';

const INITIAL_STATE = [];

const feeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case feeActionType.LOAD_FEE:
      return action.payload;
    case feeActionType.ADD_FEE:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default feeReducer;
