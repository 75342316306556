const Spinner = () => {
  return (
    <div className="flex justify-center items-center flex-col">
      <div
        className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-24 w-24 animate-spin"
        style={{ borderTopColor: 'rgba(16, 185, 129,1)' }}
      ></div>
      Loading...
    </div>
  );
};

export default Spinner;
