import { userActionType } from './user.action.type';

const sessionData = sessionStorage.getItem('sfms-user');

const INITIAL_STATE = {
  currentUser: JSON.parse(sessionData) || null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case userActionType.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
