import PaymentCharts from 'components/charts/paymentCharts';
import ClassStrengthCharts from '../charts/classStrengthCharts';
import ViewRecentFee from './recentFee';

const Dashboard = () => {
  return (
    <div className="bg-gray-100 w-full h-full p-5 shadow-lg rounded-lg">
      <div className="w-full flex flex-row justify-between">
        <ClassStrengthCharts />
        <PaymentCharts />
      </div>
      <ViewRecentFee />
    </div>
  );
};

export default Dashboard;
