import { memo } from 'react';
import { FaEdit } from 'react-icons/fa';

const ViewBusRoutes = ({ busRoute, idx }) => {
  return (
    <tr key={busRoute._id} className="text-left hover:bg-green-50">
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{idx + 1}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{busRoute.route}</div>
      </td>
      <td className="px-2 whitespace-nowrap text-sm font-medium">
        <div className="">
          <button className="p-2 m-1 pointer-events-none rounded-md bg-green-500 hover:bg-green-600 text-white">
            <FaEdit />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default memo(ViewBusRoutes);
