import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loadStudent } from 'redux/students/students.action';
const TransportFees = () => {
  const students = useSelector((state) => state.students);
  const [filteredStd, setFilteredStd] = useState([]);
  const [tStdnts, setTStdnts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    !students.length > 0 && dispatch(loadStudent());
    setTStdnts((preS) => students.filter((std) => std.transport));
  }, [students, dispatch]);

  const handleSearch = (e) => {
    e.preventDefault();

    const nArr = tStdnts.filter(
      ({ studentsName, fathersName, address, mothersName, mobile, dob }) => {
        return Object.values({
          studentsName,
          fathersName,
          address,
          mothersName,
          mobile,
          dob,
        })
          .join(' ')
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      }
    );
    setFilteredStd(nArr);
    setSearchQuery('');
  };

  return (
    <div>
      <h1 className="w-full text-center font-medium font-serif text-2xl text-gray-700 p-5">
        Collect Transport Fee Of Student
      </h1>
      <form
        onSubmit={handleSearch}
        className="w-full flex justify-around px-16 py-2"
      >
        <input
          type="search"
          placeholder="Search by StudentsName or Fathers Name or Mothers Name or Mobile or Address "
          className="  border-gray-300  flex-1 flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button
          type="submit"
          className="  bg-green-600 p-1 ml-5 text-white py-2 rounded-xl px-5 hover:bg-green-700 "
          onClick={handleSearch}
        >
          Search
        </button>
      </form>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50 ">
          <tr className="text-left">
            <th
              scope="col"
              className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
            >
              Admn No.
            </th>
            <th
              scope="col"
              className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
            >
              Name
            </th>
            <th
              scope="col"
              className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
            >
              Father's Name
            </th>
            <th
              scope="col"
              className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
            >
              Class
            </th>
            <th
              scope="col"
              className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
            >
              Address
            </th>
            <th
              scope="col"
              className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
            >
              Mobile
            </th>
            <th
              scope="col"
              className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
            >
              Pay Fee
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {filteredStd &&
            filteredStd.map((std) => {
              return (
                <tr
                  key={std.studentsName}
                  className="text-left hover:bg-green-50"
                >
                  <td className=" px-2 py-2 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {std.admissionNo}
                    </div>
                  </td>
                  <td className=" px-2 py-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {std.studentsName}
                    </div>
                  </td>
                  <td className=" px-2 py-2 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {std.fathersName}
                    </div>
                  </td>
                  <td className=" px-2 py-2 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {std._class._class}
                    </div>
                  </td>
                  <td className=" px-2 py-2 whitespace-pre-wrap text-sm text-gray-500">
                    {std.address}
                  </td>
                  <td className="px-2 py-2 whitespace-pre-wrap text-sm text-gray-500">
                    {std.mobile}
                  </td>
                  <td className="px-2 py-2 whitespace-pre-wrap text-sm text-gray-500">
                    <button
                      type="submit"
                      className="  bg-white p-1  text-green-500 border border-green-500 py-2 rounded-xl px-5 hover:border-gray-700 "
                      onClick={(e) =>
                        history.push(`/transportFees/pay/${std._id}`, std)
                      }
                    >
                      Pay
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TransportFees;
