import React from 'react';
import AddFeeStructure from './addFeeStructure';
import Model from 'components/model.component';

const EditFeeStructure = ({ handleClick, feeStructure }) => {
  return (
    <Model
      handleClick={handleClick}
      title={'Edit Fee Structure'}
      className="w-2/3"
    >
      <AddFeeStructure feeStructure={feeStructure} handleSubmit={handleClick} />
    </Model>
  );
};

export default EditFeeStructure;
