import React from 'react';
import AddTransportFS from './addTransportFS';
import Model from 'components/model.component';

const EditTransportFS = ({ handleClick, transportFS }) => {
  return (
    <AddTransportFS transportFS={transportFS} handleUpdate={handleClick} />
  );
};

export default EditTransportFS;
