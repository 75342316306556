import { feeHeadActionTypes } from './feeHead.actionTypes';

const INITIAL_STATE = [];

const classReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case feeHeadActionTypes.LOAD_FEE_HEAD:
      return action.payload;
    case feeHeadActionTypes.ADD_FEE_HEAD:
      return [...state, action.payload];
    case feeHeadActionTypes.REMOVE_FEE_HEAD:
      return removeFHead(state, action.payload);
    default:
      return state;
  }
};

export default classReducer;

const removeFHead = (fhead, fheadToDel) => {
  const newData = fhead.filter((fh) => fh._id !== fheadToDel._id);
  return newData;
};
