import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { addClass } from 'redux/class/class.action';

const AddClass = () => {
  const [_class, set_Class] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (_class !== '') {
      dispatch(addClass(_class));
      history.push('/classes');
    }
  };

  return (
    <div className="w-full">
      <div className="w-1/2 mx-auto text-center text-lg font-semibold rounded-lg shadow-sm text-green-900 p-2 m-4 bg-white border-b-2 border-green-900">
        Add Class
      </div>
      <form className="w-1/2 mx-auto text-gray-700 ">
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Class :</label>
          <input
            type="text"
            name="_class"
            value={_class}
            onChange={(e) => set_Class(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>

        <div className="flex flex-row mt-2 ">
          <button
            className="w-full m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
          <button
            className="w-full m-5  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-red-900"
            onClick={() => history.push('/classes')}
          >
            Go Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddClass;
