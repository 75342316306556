import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, FormikProvider, Field, Form } from 'formik';
import { loadBusRoutes } from 'redux/busRoutes/busRoutes.action';
import {
  addTransportFS,
  updateTransportFS,
} from 'redux/transportFS/transportFS.action';
import Modal from 'components/model.component';
import SelectSearch, { fuzzySearch } from 'react-select-search';

import { toast } from 'react-toastify';

const AddTransportFS = ({ handleClick, handleUpdate, transportFS }) => {
  const { busRoutes } = useSelector((state) => state);
  const options = busRoutes.map((bR) => {
    return { name: bR.route, value: bR._id };
  });

  const [route, setRoute] = useState(
    transportFS
      ? options?.find((r) => r.value === transportFS?.route?._id).value
      : ''
  );

  const dispatch = useDispatch();
  useEffect(() => {
    !busRoutes.length > 0 && dispatch(loadBusRoutes());
  }, [busRoutes.length, dispatch]);

  const formik = useFormik({
    initialValues: {
      amount: transportFS?.amount || '',
      period: 'monthly',
      months: transportFS?.months || [
        'apr',
        'may',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
        'jan',
        'feb',
        'mar',
      ],
    },
    onSubmit: (values) => {
      if (!transportFS) {
        if (!route) toast.warn('Please Select Route');

        dispatch(addTransportFS({ route, ...values }));
        handleClick();
      } else {
        dispatch(updateTransportFS({ route, ...values }, transportFS._id));
        handleUpdate();
      }
    },

    validate: (values) => {
      let errors = {};
      if (!values.amount) errors.amount = 'Required';
      if (!values.period) errors.period = 'Required';
      if (!values.months.length) errors.months = 'Required';
      return errors;
    },
  });

  return (
    <Modal
      title={
        transportFS
          ? 'EDIT TRANSPORT FEE STRUCTURE'
          : 'Add Transport Fee Structure'
      }
      handleClick={!transportFS ? handleClick : handleUpdate}
      className="w-1/2"
    >
      <FormikProvider value={formik}>
        <Form className="w-full p-10 mx-auto">
          <div className="flex  flex-row mt-2 items-center">
            <label className="w-1/2">Bus Route :</label>
            <div className="w-full">
              <SelectSearch
                name="route"
                options={options}
                value={route}
                onChange={(val) => {
                  console.log({ val, route });
                  setRoute(val);
                }}
                search
                filterOptions={fuzzySearch}
                placeholder="Search route"
              />
            </div>
          </div>

          <div className="flex flex-row mt-2 items-center ">
            <label className="w-1/2">Amount :</label>
            <div className="relative w-full">
              <Field
                type="number"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                className=" border-gray-300 w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className="text-red-500">{formik.errors.amount}</div>
              )}
            </div>
          </div>

          <label className="w-1/2">Months :</label>
          <div className="flex flex-col flex-wrap h-24 justify-start items-start ml-52">
            {[
              'apr',
              'may',
              'jun',
              'jul',
              'aug',
              'sep',
              'oct',
              'nov',
              'dec',
              'jan',
              'feb',
              'mar',
            ].map((month, idx) => (
              <div key={idx}>
                <Field type="checkbox" name="months" value={month} />
                <label> {month.toUpperCase()}</label>
              </div>
            ))}
          </div>
          {formik.touched.months && formik.errors.months && (
            <div className="text-red-500">{formik.errors.months}</div>
          )}

          <div className="w-full flex justify-center items-center">
            <button
              className="w-1/2  m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Submit
            </button>
          </div>
        </Form>
      </FormikProvider>
    </Modal>
  );
};

export default AddTransportFS;
