import { studentActionType } from './students.actionTypes';

const INITIAL_STATE = [];

const studentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case studentActionType.LOAD_STUDENT:
      return action.payload;
    case studentActionType.ADD_STUDENT:
      return [...state, action.payload];
    case studentActionType.UPDATE_STUDENT:
      return updateStd(state, action.payload);
    case studentActionType.REMOVE_STUDENT:
      return removeStd(state, action.payload);

    default:
      return state;
  }
};

export default studentReducer;

const removeStd = (students, stdToDel) => {
  const newData = students.filter((std) => std._id !== stdToDel._id);
  return newData;
};
const updateStd = (students, studentsToUpdate) => {
  const index = students.findIndex((std) => std._id === studentsToUpdate._id);

  return [
    ...students.slice(0, index),
    { ...studentsToUpdate },
    ...students.slice(index + 1),
  ];
};
