import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { loadClass } from 'redux/class/class.action';

import ViewClass from 'components/class/viewClass';

const Classes = () => {
  const classes = useSelector((state) => state.classes);
  const dispatch = useDispatch();
  useEffect(() => {
    !classes.length > 0 && dispatch(loadClass());
  }, [classes, dispatch]);

  const history = useHistory();

  return (
    <div className="flex flex-col">
      <div className="m-5 flex justify-center gap-5">
        <button
          className="border border-green-500 rounded-lg p-2 text-green-500 hover:bg-green-500 hover:border-opacity-0 hover:text-white"
          onClick={(e) => {
            history.push('class/add');
          }}
        >
          Add Class
        </button>
      </div>
      <div className="overflow-x-auto p-5">
        <div className="  align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 ">
                <tr className="text-left">
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Sl No.
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Class
                  </th>

                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {classes &&
                  classes.map((cls, idx) => {
                    return <ViewClass cls={cls} key={idx} idx={idx} />;
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classes;
