import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadClass } from 'redux/class/class.action';
import { useFormik, FormikProvider, Field, Form } from 'formik';
import { loadFeeStructure } from 'redux/feeStructures/feeStructure.action';
import { loadStudent } from 'redux/students/students.action';
import Spinner from 'components/spinner';
import { useReactToPrint } from 'react-to-print';
import { exportExcel } from 'utils/exportExcel';

const DuesList = () => {
  const { classes, feeStructures, students } = useSelector((state) => state);
  const [stdFeeDues, setStdFeeDues] = useState([]);
  const [stdPaidList, setStdPaidList] = useState([]);
  const [isSecondBtn, setIsSecondBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  let allMonths = [
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'jan',
    'feb',
    'mar',
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    !classes.length ? dispatch(loadClass()) : setLoading(false);
    !feeStructures.length ? dispatch(loadFeeStructure()) : setLoading(false);
    !students.length ? dispatch(loadStudent()) : setLoading(false);
  }, [classes.length, students.length, feeStructures.length, dispatch]);

  const formik = useFormik({
    initialValues: {
      _class: '',
      months: [],
    },
    onSubmit: (values) => {
      let filterMonths = allMonths.filter((mon) => values.months.includes(mon));
      let filterStudents = students.filter(
        (std) => std._class?._id === values._class
      );

      const myFeeStructures =
        feeStructures &&
        feeStructures.filter((fStruct) => {
          return fStruct._class._id === values._class;
        });

      const nArr = filterStudents.map((std) => {
        let duesMonths = filterMonths.filter(
          (mon) => !std.paidMonths.includes(mon)
        );

        let test = myFeeStructures.map((mFs) =>
          mFs.months.filter((mfs) => duesMonths.includes(mfs))
        );
        const total = myFeeStructures.map(
          (ele, idx) => ele.amount * test[idx].length
        );
        const totalFeeWithoutOldBal = total.reduce(
          (pre, curr) => pre + curr,
          0
        );

        return {
          ...std,
          duesMonths: duesMonths,
          dues: totalFeeWithoutOldBal,
        };
      });

      if (isSecondBtn) {
        const paidList = nArr.filter((std) => !std.dues > 0);
        setStdPaidList(paidList);
      } else {
        const duesList = nArr.filter((std) => std.dues > 0);
        setStdFeeDues(duesList);
      }
    },

    validate: (values) => {
      let errors = {};
      if (!values._class) errors._class = 'Required';
      if (!values.months.length) errors.months = 'Required';
      return errors;
    },
  });

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div ref={ref} className="w-full p-10 pt-5">
          <h1 className="w-full pb-5 text-center font-medium font-serif text-2xl text-red-500 ">
            Dues List ClassWise
          </h1>

          <FormikProvider value={formik}>
            <Form className="w-full">
              <div className="flex">
                <label className="mr-5">Months :</label>
                <div className="flex-1 flex flex-row flex-wrap w-full justify-start">
                  {allMonths.map((month, idx) => (
                    <div key={idx} className="ml-5">
                      <Field type="checkbox" name="months" value={month} />
                      <label> {month.toUpperCase()}</label>
                    </div>
                  ))}
                  {formik.touched.months && formik.errors.months && (
                    <div className="text-red-500">{formik.errors.months}</div>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-center mt-5">
                <label className="w-1/6">Class :</label>
                <div className="w-full space-x-8">
                  <select
                    name="_class"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values._class}
                    className=" border-gray-300 w-1/3 flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                  >
                    <option value="Select">Select</option>
                    {classes &&
                      classes.map((cl) => (
                        <option value={cl._id} key={cl._id}>
                          {cl._class}
                        </option>
                      ))}
                  </select>
                  <button
                    type="button"
                    className="px-5 py-1  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-green-900"
                    onClick={() => {
                      formik.handleSubmit();
                      setIsSecondBtn(false);
                    }}
                  >
                    Get Dues List
                  </button>
                  <button
                    type="button"
                    className="px-5 py-1  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
                    onClick={() => {
                      formik.handleSubmit();
                      setIsSecondBtn(true);
                    }}
                  >
                    Get Paid List
                  </button>
                  <button
                    onClick={handlePrint}
                    className="ml-96 mt-5 p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
                  >
                    Print Record
                  </button>

                  {formik.touched._class && formik.errors._class && (
                    <div className="text-red-500">{formik.errors._class}</div>
                  )}
                  <button
                    onClick={() => {
                      const data = isSecondBtn ? stdPaidList : stdFeeDues;
                      exportExcel({
                        data: data.map((std, idx) => {
                          return {
                            'S/N': idx + 1,
                            'Admission Number': std.admissionNo,
                            'Student Name': std.studentsName,
                            'S/D/O': std.fathersName,
                            Class: std._class?._class,
                            Mobile: std.mobile,
                            Address: std.address,
                            'Paid Months': std.paidMonths
                              ?.map((pm) => pm)
                              .join(', '),
                            'Old Balance': std.oldBalance,
                            Dues: std.dues,
                          };
                        }),
                        fileName: 'Fee Dues List.xlsx',
                      });
                    }}
                    className="ml-96 mt-5 p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
                  >
                    Download Excel
                  </button>
                </div>
              </div>
            </Form>
          </FormikProvider>
          <table className="w-full mt-5 ">
            <thead>
              <tr className="text-left border bg-yellow-100 text-sm">
                <th className="border border-gray-400  px-2 py-4">S/N</th>
                <th className="border border-gray-400  px-2 py-4">Admn</th>
                <th className="border border-gray-400  px-2 py-4">Std Name</th>
                <th className="border border-gray-400  px-2 py-4">S/D/O</th>
                <th className="border border-gray-400  px-2 py-4">Mobile</th>
                <th className="border border-gray-400  px-2 py-4">Address</th>
                <th className="border border-gray-400  px-2 py-4">
                  Paid Months
                </th>
                <th className="border border-gray-400  px-2 py-4">
                  Old Balance
                </th>
                <th className="border border-gray-400  px-2 py-4">Dues</th>
              </tr>
            </thead>

            {!isSecondBtn
              ? stdFeeDues.map((std, idx) => <ViewList std={std} idx={idx} />)
              : stdPaidList.map((std, idx) => <ViewList std={std} idx={idx} />)}
          </table>
        </div>
      )}
    </div>
  );
};

export default DuesList;

const ViewList = ({ std, idx }) => {
  return (
    <tbody key={std._id}>
      <tr>
        <td className="border border-gray-400  px-2 py-2">{idx + 1}</td>
        <td className="border border-gray-400  px-2 py-2">{std.admissionNo}</td>
        <td className="border border-gray-400  px-2 py-2">
          {std.studentsName}
        </td>
        <td className="border border-gray-400  px-2 py-2">{std.fathersName}</td>
        <td className="border border-gray-400  px-2 py-2">{std.mobile}</td>
        <td className="border border-gray-400  px-2 py-2">{std.address}</td>
        <td className="border border-gray-400  px-2 py-2">
          {std.paidMonths.map((mon) => (
            <span>{mon}, </span>
          ))}
        </td>
        <td className="border border-gray-400  px-2 py-2">{std.oldBalance}</td>
        <td className="border border-gray-400  px-2 py-2">{std.dues}</td>
      </tr>
    </tbody>
  );
};
