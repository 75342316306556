import { busRoutesActionTypes } from './busRoutes.actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';

export const addBusRoutes = (route) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}route`,
      data: {
        route: route,
      },
    }).then((res) => {
      dispatch({
        type: busRoutesActionTypes.ADD_BUS_ROUTES,
        payload: res.data.result,
      });
      toast.success('Added Bus Route');
    });
  };
};

export const loadBusRoutes = () => {
  return (dispatch) => {
    axios(`${process.env.REACT_APP_SERVER_URL}route`).then((res) => {
      dispatch({
        type: busRoutesActionTypes.LOAD_BUS_ROUTES,
        payload: res.data.result,
      });
      toast.success('Loaded Bus Route');
    });
  };
};
