import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik, Form, Field, FormikProvider } from 'formik';
import { addStudent } from 'redux/students/students.action';
import { loadClass } from 'redux/class/class.action';
import { useHistory } from 'react-router';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { loadBusRoutes } from 'redux/busRoutes/busRoutes.action';
import { loadVan } from 'redux/van/van.action';

const AddStudent = () => {
  const history = useHistory();
  const classes = useSelector((state) => state.classes);
  const { busRoutes } = useSelector((state) => state);
  const vans = useSelector((state) => state.van);

  const busRouteOptions = busRoutes.map((bR) => {
    return { name: bR.route, value: bR._id };
  });

  const vanOptions = vans?.map((van) => {
    return { name: `${van.vanNumber} ${van.driverName}`, value: van._id };
  });

  const [route, setRoute] = useState('');
  const [van, setVan] = useState('');
  const [isTransport, setIsTransport] = useState(false);
  const formik = useFormik({
    initialValues: {
      studentsName: '',
      fathersName: '',
      gender: '',
      mothersName: '',
      _class: '',
      dob: '',
      mobile: '',
      srNo: '',
      admissionNo: '',
      address: '',
    },
    onSubmit: (values) => {
      dispatch(
        addStudent({
          ...values,
          route,
          van,
          transport: isTransport,
        })
      );
      history.replace('/students');
    },

    validate: (values) => {
      let errors = {};
      if (!values.studentsName) errors.studentsName = 'Required';
      if (!values.fathersName) errors.fathersName = 'Required';
      if (!values._class) errors._class = 'Required';
      if (!values.gender) errors.gender = 'Required';

      return errors;
    },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    !classes.length > 0 && dispatch(loadClass());
  }, [classes.length, dispatch]);

  useEffect(() => {
    !busRoutes.length > 0 && dispatch(loadBusRoutes());
  }, [busRoutes.length, dispatch]);

  useEffect(() => {
    !vans.length > 0 && dispatch(loadVan());
  }, [vans.length, dispatch]);

  return (
    <div className="w-full">
      <div className="w-1/2 mx-auto text-center text-lg font-semibold rounded-lg shadow-sm text-green-900 p-2 m-4 bg-white border-b-2 border-green-900">
        Add Student
      </div>
      <FormikProvider value={formik}>
        <Form className="w-1/2 mx-auto text-gray-700 ">
          <div className="flex  flex-row mt-2 items-center">
            <label className="w-1/2">Addmission No :</label>
            <Field
              type="text"
              name="admissionNo"
              value={formik.admissionNo}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
          <div className="flex flex-row mt-2 items-center">
            <label className="w-1/2">SR No :</label>
            <Field
              type="text"
              name="srNo"
              value={formik.srNo}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
          <div className="flex relative flex-row mt-2 items-center">
            <label className="w-1/2">Student's Name* :</label>
            <Field
              type="text"
              name="studentsName"
              value={formik.studentsName}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
            {formik.touched.studentsName && formik.errors.studentsName && (
              <p className="absolute right-2 text-red-500 font-semibold">
                {formik.errors.studentsName}
              </p>
            )}
          </div>
          <div className="flex relative flex-row mt-2 items-center">
            <label className="w-1/2">Gender* :</label>
            <select
              name="gender"
              value={formik.gender}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              onChange={formik.handleChange}
            >
              <option>Select Gender</option>
              <option>Male</option>
              <option>Female</option>
            </select>
            {formik.touched.gender && formik.errors.gender && (
              <p className="absolute right-2 text-red-500 font-semibold">
                {formik.errors.gender}
              </p>
            )}
          </div>
          <div className="relative flex flex-row mt-2 items-center">
            <label className="w-1/2">Father's Name* :</label>
            <Field
              type="text"
              name="fathersName"
              value={formik.fathersName}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
            {formik.touched.fathersName && formik.errors.fathersName && (
              <p className="absolute right-2 text-red-500 font-semibold">
                {formik.errors.fathersName}
              </p>
            )}
          </div>
          <div className="flex flex-row mt-2 items-center">
            <label className="w-1/2">Mother's Name :</label>
            <Field
              type="text"
              name="mothersName"
              value={formik.mothersName}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
          <div className="flex flex-row mt-2 items-center">
            <label className="w-1/2">Date Of Birth :</label>
            <Field
              type="text"
              name="dob"
              placeholder="dd/mm/yyyy"
              value={formik.dob}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
          <div className="flex flex-row mt-2 items-center">
            <label className="w-1/2">Mobile No :</label>
            <Field
              type="tel"
              name="mobile"
              value={formik.mobile}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>

          <div className="flex relative flex-row mt-2 items-center">
            <label className="w-1/2">Class* :</label>
            <select
              name="_class"
              value={formik._class}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              onChange={formik.handleChange}
            >
              <option>Select Class</option>
              {classes &&
                classes.map((cl) => (
                  <option key={cl._id} value={cl._id}>
                    {cl._class}
                  </option>
                ))}
            </select>
            {formik.touched._class && formik.errors._class && (
              <p className="absolute right-2 text-red-500 font-semibold">
                {formik.errors._class}
              </p>
            )}
          </div>
          <div className="flex flex-row mt-2">
            <label className="w-1/2">Address :</label>
            <textarea
              type="text"
              name="address"
              value={formik.address}
              onChange={formik.handleChange}
              className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
          <br />
          <div className="flex relative flex-row mt-2 items-center">
            <label className="w-1/2">Transport* :</label>
            <select
              name="isTransport"
              value={isTransport}
              className=" border-gray-300 mb-2  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              onChange={() => setIsTransport(!isTransport)}
            >
              <option value={false}>No</option>
              <option value={true}>Yes</option>
            </select>
          </div>
          {isTransport ? (
            <React.Fragment>
              <div className="flex flex-row mt-2 items-center">
                <label className="w-1/2">Select Van :</label>
                <SelectSearch
                  name="van"
                  options={vanOptions}
                  value={van}
                  onChange={setVan}
                  search
                  filterOptions={fuzzySearch}
                  placeholder="Search Van"
                />
              </div>

              <div className="flex flex-row mt-2 items-center">
                <label className="w-1/2">Select Bus Route :</label>
                <SelectSearch
                  name="route"
                  options={busRouteOptions}
                  value={route}
                  onChange={setRoute}
                  search
                  filterOptions={fuzzySearch}
                  placeholder="Search route"
                />
              </div>
            </React.Fragment>
          ) : null}

          <div className="flex flex-row mt-2 ">
            <button
              className="w-full m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
              onClick={formik.handleSubmit}
              type="submit"
            >
              Submit
            </button>
            <button
              type="reset"
              className="w-full m-5  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-red-900"
              onClick={() => history.push('/students')}
            >
              Go Back
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default AddStudent;
