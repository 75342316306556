import { useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom';
import Spinner from 'components/spinner';
import axios from 'axios';
import { exportExcel } from 'utils/exportExcel';

const ViewTransportFees = () => {
  const [transportFees, setTransportFees] = useState([]);
  const [frm, setFrm] = useState('');
  const [to, setTo] = useState('');
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const history = useHistory();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const handleSearch = async () => {
    setLoading(true);
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}transportFeesbydate?frm=${frm}&&to=${to}`,
    });
    if (res.data.status !== 'success') setLoading(true);
    setTransportFees(res.data.result);
    setLoading(false);
  };
  return (
    <div>
      <div
        ref={ref}
        className="font-mono text-gray-700 bg-white p-5 my-5 shadow-2xl rounded-lg"
      >
        <h1 className="w-full text-center font-medium font-serif text-2xl text-green-700 ">
          All Transport Payments
        </h1>
        <div className="mt-5 flex flex-row justify-center w-full space-x-5">
          <div className="">
            <label className="mr-5 font-semibold">Date From</label>
            <input
              type="date"
              name="frm"
              value={frm}
              onChange={(e) => setFrm(e.target.value)}
              className=" border-gray-300 flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
          <div>
            <label className="mr-5 font-semibold">Date to</label>
            <input
              type="date"
              name="to"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className=" border-gray-300 flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            />
          </div>
          <button
            onClick={handleSearch}
            className="  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-red-900"
          >
            Search Record
          </button>
        </div>

        <div className="p-1 flex flex-row justify-center items-center">
          <button
            onClick={handlePrint}
            className="  p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
          >
            Print Record
          </button>
          <button
            onClick={() => {
              exportExcel({
                data: transportFees.map((fee, idx) => {
                  const date = new Date(fee.paidDate);

                  const [month, day, year] = [
                    date.getMonth() + 1,
                    date.getDate(),
                    date.getFullYear(),
                  ];
                  const [hour, minutes, seconds] = [
                    date.getHours(),
                    date.getMinutes(),
                    date.getSeconds(),
                  ];
                  console.log({
                    fee,
                  });

                  const dateAndTime = `${day}-${month}-${year} | ${hour}:${minutes}:${seconds}`;

                  return {
                    'S/N': idx + 1,
                    'Reciept No': fee.recieptNo,
                    'Admission Number': fee.student?.admissionNo,
                    'Student Name': fee.student?.studentsName,
                    'S/D/O': fee.student?.fathersName,
                    Class: fee.student?._class?._class,
                    Mobile: fee.student?.mobile,
                    Route: fee.student?.route?.route,
                    'Paid Months': fee.paidMonths
                      .map((month) => month)
                      .join(', '),
                    'Amount Paid': fee.totalFee,
                    'Payment Mode': fee.paymentMode,
                    'Date & Time': dateAndTime,
                    Remarks: fee.remarks,
                  };
                }),
                fileName: `All Transport Payments ${frm} to ${to}.xlsx`,
              });
            }}
            className="p-2 m-5 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
          >
            Download Excel
          </button>
          <button
            onClick={() => history.replace('/')}
            className="m-5 px-5  p-2 text-red-700 border border-red-500  rounded-md hover:bg-gray-50"
          >
            Close
          </button>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <table className="w-full">
            <thead>
              <tr className="text-left border bg-gray-50 text-sm">
                <th className="border  px-2">SN</th>
                <th className="border  px-2">Rec No.</th>
                <th className="border  px-2">Admission No.</th>
                <th className="border  px-2">Std. Name</th>
                <th className="border  px-2">S/D/O</th>
                <th className="border  px-2">Class</th>
                <th className="border  px-2">Route</th>
                <th className="border  px-2">Total Fee</th>
                <th className="border  px-2">Months</th>
                <th className="border  px-2">Mode</th>
                <th className="border  px-2">Date & Time</th>
                <th className="border  px-2">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {transportFees.map((ar, idx) => {
                const date = new Date(ar.paidDate);

                const [month, day, year] = [
                  date.getMonth() + 1,
                  date.getDate(),
                  date.getFullYear(),
                ];
                const [hour, minutes, seconds] = [
                  date.getHours(),
                  date.getMinutes(),
                  date.getSeconds(),
                ];

                const dateAndTime = `${day}-${month}-${year} | ${hour}:${minutes}:${seconds}`;
                return (
                  <tr className="text-left border text-sm " key={idx}>
                    <td className="border  px-2">{idx + 1}</td>
                    <td className="border  px-2">{ar.recieptNo}</td>
                    <td className="border  px-2">{ar.student?.admissionNo}</td>
                    <td className="border  px-2">
                      {ar?.student?.studentsName}
                    </td>
                    <td className="border  px-2">{ar?.student?.fathersName}</td>
                    <td className="border  px-2">
                      {ar?.student?._class?._class}
                    </td>
                    <td className="border  px-2">{ar.student.route.route}</td>
                    <td className="border  px-2">{ar.totalFee}</td>
                    <td className="border  px-1 text-xs">
                      {ar.paidMonths.map((mon) => (
                        <span>{mon}, </span>
                      ))}
                    </td>
                    <td className="border  px-2">{ar.paymentMode}</td>
                    <td className="border  px-2">{dateAndTime}</td>
                    <td className="border  px-2">{ar.remarks}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ViewTransportFees;
