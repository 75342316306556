import Modal from 'components/model.component';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { loadBusRoutes } from 'redux/busRoutes/busRoutes.action';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { upadateStudent } from 'redux/students/students.action';
import SelectSearch, { fuzzySearch } from 'react-select-search';

const AddTransportFac = ({ handleClick, student }) => {
  const dispatch = useDispatch();
  const { busRoutes } = useSelector((state) => state);
  const [route, setRoute] = useState('');
  const options = busRoutes.map((bR) => {
    return { name: bR.route, value: bR._id };
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (route !== '') {
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}student/${student._id}`,
        { route }
      );

      toast.success('Added Successfull !', {
        position: 'top-center',
        autoClose: 5000,
      });
      dispatch(upadateStudent(res.data.result));
      handleClick();
    }
  };

  useEffect(() => {
    !busRoutes.length > 0 && dispatch(loadBusRoutes());
  }, [busRoutes.length, dispatch]);

  return (
    <Modal
      title={`Add Transport Facilities`}
      handleClick={handleClick}
      className="w-1/3"
    >
      <div className="p-5">
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Select Bus Route :</label>
          <SelectSearch
            name="route"
            options={options}
            value={route}
            onChange={setRoute}
            search
            filterOptions={fuzzySearch}
            placeholder="Search route"
          />
        </div>
      </div>
      <button
        className="w-1/3 m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
        onClick={handleSubmit}
        type="submit"
      >
        Submit
      </button>
    </Modal>
  );
};

export default AddTransportFac;
