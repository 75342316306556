import { studentActionType } from './students.actionTypes';
import { toast } from 'react-toastify';
import axios from 'axios';

export const addStudent = (student) => {
  return (dispatch) => {
    const id = toast.loading('Adding...');
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}student`,
      data: {
        ...student,
      },
    })
      .then((res) => {
        if (!res.data.result) return;
        dispatch({
          type: studentActionType.ADD_STUDENT,
          payload: res.data.result,
        });
        toast.update(id, {
          render: 'Student Added Success',
          type: 'success',
          autoClose: 3000,
          isLoading: false,
        });
      })
      .catch((err) =>
        toast.update(id, {
          render: 'Adding Failed',
          type: 'error',
          autoClose: 5000,
          isLoading: false,
        })
      );
  };
};

export const removeStudent = (student) => {
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_SERVER_URL}student/${student._id}`,
    }).then((res) => {
      dispatch({
        type: studentActionType.REMOVE_STUDENT,
        payload: student,
      });
    });
  };
};

export const upadateStudent = (student) => {
  return (dispatch) => {
    dispatch({
      type: studentActionType.UPDATE_STUDENT,
      payload: student,
    });
  };
};

export const loadStudent = () => {
  const id = toast.loading('Loading Students...');
  return (dispatch) => {
    axios(`${process.env.REACT_APP_SERVER_URL}student`)
      .then((res) => {
        dispatch({
          type: studentActionType.LOAD_STUDENT,
          payload: res.data.result,
        });
        toast.update(id, {
          render: 'Students Loaded Success !',
          type: 'success',
          autoClose: 3000,
          isLoading: false,
        });
      })
      .catch((err) =>
        toast.update(id, {
          render: 'Loading Failed',
          type: 'error',
          autoClose: 5000,
          isLoading: false,
        })
      );
  };
};
