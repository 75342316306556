import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import thunk from 'redux-thunk';

import rootReducer from './root.reducer';
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'students',
    'busRoutes',
    'transportFS',
    'feeStructures',
    'feeHeads',
    'classes',
    'sidebar',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk)
  // other store enhancers if any
);

const store = createStore(persistedReducer, enhancer);
const persistor = persistStore(store);

export default store;
export { store, persistor };
