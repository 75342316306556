import { vanActionTypes } from './van.actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';

export const addVan = (vanData) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}van`,
      data: {
        ...vanData,
      },
    }).then((res) => {
      dispatch({
        type: vanActionTypes.ADD_VAN,
        payload: res.data.result,
      });
      toast.success('Added Van');
    });
  };
};

export const editVan = (vanData, id) => {
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}van/${id}`,
      data: {
        ...vanData,
      },
    }).then((res) => {
      dispatch({
        type: vanActionTypes.EDIT_VAN,
        payload: res.data.result,
      });
      toast.success('Updated Van');
    });
  };
};

export const loadVan = () => {
  return (dispatch) => {
    axios(`${process.env.REACT_APP_SERVER_URL}van`).then((res) => {
      dispatch({
        type: vanActionTypes.LOAD_VAN,
        payload: res.data.result,
      });
      toast.success('Loaded Van');
    });
  };
};
