import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik, FormikProvider, Field, Form } from 'formik';
import { loadStudent } from 'redux/students/students.action';
import Spinner from 'components/spinner';
import { useReactToPrint } from 'react-to-print';
import { loadBusRoutes } from 'redux/busRoutes/busRoutes.action';
import { loadTransportFS } from 'redux/transportFS/transportFS.action';
import { exportExcel } from 'utils/exportExcel';
import { loadVan } from 'redux/van/van.action';

const TransportDuesListVanWise = () => {
  const {
    busRoutes,
    transportFS,
    students,
    van: vans,
  } = useSelector((state) => state);
  const [stdFeeDues, setStdFeeDues] = useState([]);
  const [loading, setLoading] = useState(true);
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  let allMonths = [
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'jan',
    'feb',
    'mar',
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    !busRoutes.length ? dispatch(loadBusRoutes()) : setLoading(false);
    !transportFS.length ? dispatch(loadTransportFS()) : setLoading(false);
    !students.length ? dispatch(loadStudent()) : setLoading(false);
    !vans.length ? dispatch(loadVan()) : setLoading(false);
  }, [
    busRoutes.length,
    students.length,
    transportFS.length,
    vans.length,
    dispatch,
  ]);

  const formik = useFormik({
    initialValues: {
      van: '',
      months: [],
    },
    onSubmit: (values) => {
      let filterMonths = allMonths.filter((mon) => values.months.includes(mon));
      let filterStudents = students
        .filter((std) => std.transport && std.van?._id === values.van)
        .map((std) => {
          return {
            ...std,
            transportFs: transportFS.find(
              (tFs) => tFs.route?._id === std.route?._id
            ),
            duesMonths: filterMonths.filter(
              (fm) => !std.transportMonths.includes(fm)
            ),
          };
        });

      const nArr = filterStudents.map((std) => {
        return {
          ...std,
          dues: std.transportFs?.amount * std.duesMonths.length,
        };
      });

      let dues = nArr.filter((std) => std.dues > 0);
      setStdFeeDues(dues);
    },

    validate: (values) => {
      let errors = {};
      if (!values.van) errors.van = 'Required';
      if (!values.months.length) errors.months = 'Required';
      return errors;
    },
  });

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div ref={ref} className="w-full p-10 pt-5">
          <h1 className="w-full pb-5 text-center font-medium font-serif text-2xl text-red-500 ">
            Transport Dues List Van Wise
          </h1>
          <FormikProvider value={formik}>
            <Form className="w-full">
              <div className="flex">
                <label className="mr-5">Months :</label>
                <div className="flex-1 flex flex-row flex-wrap w-full justify-start">
                  {allMonths.map((month, idx) => (
                    <div key={idx} className="ml-5">
                      <Field type="checkbox" name="months" value={month} />
                      <label> {month.toUpperCase()}</label>
                    </div>
                  ))}
                  {formik.touched.months && formik.errors.months && (
                    <div className="text-red-500">{formik.errors.months}</div>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-center mt-5">
                <label className="w-1/6">Van :</label>
                <div className="w-full space-x-8">
                  <select
                    name="van"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.van}
                    className=" border-gray-300 w-1/3 flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                  >
                    <option value="">Select</option>
                    {vans &&
                      vans.map((v) => (
                        <option value={v._id} key={v._id}>
                          {v.vanNumber + ' ' + v.driverName}
                        </option>
                      ))}
                  </select>
                  <button
                    type="button"
                    className="px-5 py-1  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
                    onClick={formik.handleSubmit}
                  >
                    Get Dues List
                  </button>
                  <button
                    onClick={handlePrint}
                    className="ml-96 mt-5 p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
                  >
                    Print Record
                  </button>
                  {formik.touched.route && formik.errors.route && (
                    <div className="text-red-500">{formik.errors.route}</div>
                  )}
                  <button
                    onClick={() => {
                      const data = stdFeeDues;
                      exportExcel({
                        data: data.map((std, idx) => {
                          return {
                            'S/N': idx + 1,
                            'Admission Number': std.admissionNo,
                            'Student Name': std.studentsName,
                            'S/D/O': std.fathersName,
                            Class: std._class?._class,
                            Mobile: std.mobile,
                            Route: std.route?.route,
                            Address: std.address,
                            'Paid Months': std.transportMonths
                              ?.map((pm) => pm)
                              .join(', '),
                            'Dues Months': std.duesMonths
                              ?.map((dm) => dm)
                              .join(', '),
                            Dues: std.dues,
                          };
                        }),
                        fileName: 'Transport Dues List.xlsx',
                      });
                    }}
                    className="ml-96 mt-5 p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
                  >
                    Download Excel
                  </button>
                </div>
              </div>
            </Form>
          </FormikProvider>
          <table className="w-full mt-5 ">
            <thead>
              <tr className="text-left border bg-yellow-100 text-sm">
                <th className="border border-gray-400  px-2 py-4">S/N</th>
                <th className="border border-gray-400  px-2 py-4">Admn</th>
                <th className="border border-gray-400  px-2 py-4">Std Name</th>
                <th className="border border-gray-400  px-2 py-4">S/D/O</th>
                <th className="border border-gray-400  px-2 py-4">Mobile</th>
                <th className="border border-gray-400  px-2 py-4">Route</th>
                <th className="border border-gray-400  px-2 py-4">
                  Paid Months
                </th>

                <th className="border border-gray-400  px-2 py-4">Dues</th>
              </tr>
            </thead>
            <tbody>
              {stdFeeDues.map((std, idx) => (
                <tr key={std._id}>
                  <td className="border border-gray-400  px-2 py-2">
                    {idx + 1}
                  </td>
                  <td className="border border-gray-400  px-2 py-2">
                    {std.admissionNo}
                  </td>
                  <td className="border border-gray-400  px-2 py-2">
                    {std.studentsName}
                  </td>
                  <td className="border border-gray-400  px-2 py-2">
                    {std.fathersName}
                  </td>
                  <td className="border border-gray-400  px-2 py-2">
                    {std.mobile}
                  </td>
                  <td className="border border-gray-400  px-2 py-2">
                    {std.route.route}
                  </td>
                  <td className="border border-gray-400  px-2 py-2">
                    {std.transportMonths.map((mon) => (
                      <span>{mon}, </span>
                    ))}
                  </td>
                  <td className="border border-gray-400  px-2 py-2">
                    {std.dues}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TransportDuesListVanWise;
