import { classActionType } from './class.actionTypes';

const INITIAL_STATE = [];

const classReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case classActionType.LOAD_CLASS:
      return action.payload;
    case classActionType.ADD_CLASS:
      return [...state, action.payload];
    case classActionType.REMOVE_CLASS:
      return removeCls(state, action.payload);
    default:
      return state;
  }
};

export default classReducer;

const removeCls = (cls, clsToDel) => {
  const newData = cls.filter((cl) => cl._id !== clsToDel._id);
  return newData;
};
