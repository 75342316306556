import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFeeStructure,
  updateFeeStructure,
} from 'redux/feeStructures/feeStructure.action';
import { useFormik, FormikProvider, Field, Form } from 'formik';
import { loadClass } from 'redux/class/class.action';
import { loadFeeHead } from 'redux/feeHead/feeHead.action';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';

const AddFeeStructure = ({ handleSubmit, feeStructure }) => {
  const feeHeads = useSelector((state) => state.feeHeads);
  const classes = useSelector((state) => state.classes);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    !classes.length > 0 && dispatch(loadClass());
    !feeHeads.length > 0 && dispatch(loadFeeHead());
  }, [classes.length, feeHeads.length, dispatch]);

  const formik = useFormik({
    initialValues: {
      _class: feeStructure?._class?._id || '',
      feeHead: feeStructure?.feeHead._id || '',
      amount: feeStructure?.amount || '',
      period: feeStructure?.period || '',
      months: feeStructure?.months || [],
    },
    onSubmit: (values) => {
      if (!feeStructure) {
        dispatch(addFeeStructure(values));
        toast.success('Fee Structure Added');
        history.push('/feeStructures');
        formik.handleReset();
      } else {
        dispatch(updateFeeStructure(values, feeStructure?._id));
        toast.success('Fee Structure Updated');
        history.push('/feeStructures');
        formik.handleReset();
      }
    },

    validate: (values) => {
      let errors = {};
      if (!values._class) errors._class = 'Required';
      if (!values.feeHead) errors.feeHead = 'Required';
      if (!values.amount) errors.amount = 'Required';
      if (!values.period) errors.period = 'Required';
      if (!values.months.length) errors.months = 'Required';
      return errors;
    },
  });

  const handlePeriod = () => {
    if (formik.values.period === 'Yearly')
      formik.setFieldValue('months', ['apr']);
    if (formik.values.period === 'Half Yearly')
      formik.setFieldValue('months', ['apr', 'sep']);
    if (formik.values.period === 'Monthly')
      formik.setFieldValue('months', [
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec',
        'jan',
        'feb',
        'mar',
      ]);
  };

  return (
    <div className="w-full">
      <div className="w-1/2 mx-auto text-center text-lg font-semibold rounded-lg shadow-sm text-green-900 p-2 m-4 bg-white border-b-2 border-green-900">
        {feeStructure ? 'Edit Fee Structure' : 'Add Fee Structure'}
      </div>
      <FormikProvider value={formik}>
        <Form className="w-1/2 mx-auto">
          <div className="flex  flex-row mt-2 items-center">
            <label className="w-1/2">Fee Head :</label>
            <div className="w-full">
              <select
                name="feeHead"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.feeHead}
                className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              >
                <option>Select</option>
                {feeHeads &&
                  feeHeads.map((fHead) => (
                    <option value={fHead._id} key={fHead._id}>
                      {fHead.head}
                    </option>
                  ))}
              </select>
              {formik.touched._class && formik.errors.feeHead && (
                <div className="text-red-500">{formik.errors.feeHead}</div>
              )}
            </div>
          </div>

          <div className="flex flex-row mt-2 items-center">
            <label className="w-1/2">Class :</label>
            <div className="w-full">
              <select
                name="_class"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values._class}
                className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              >
                <option>Select</option>
                {classes &&
                  classes.map((cl) => (
                    <option value={cl._id} key={cl._id}>
                      {cl._class}
                    </option>
                  ))}
              </select>
              {formik.touched._class && formik.errors._class && (
                <div className="text-red-500">{formik.errors._class}</div>
              )}
            </div>
          </div>

          <div className="flex flex-row mt-2 items-center ">
            <label className="w-1/2">Amount :</label>
            <div className="relative w-full">
              <Field
                type="number"
                name="amount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                className=" border-gray-300 w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              />
              {formik.touched.amount && formik.errors.amount && (
                <div className="text-red-500">{formik.errors.amount}</div>
              )}
            </div>
          </div>

          <div className="flex flex-row mt-2 items-center">
            <label className="w-1/2">Period :</label>
            <div className="w-full">
              <select
                name="period"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.period}
                onClick={handlePeriod}
                className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
              >
                <option>Select</option>
                <option>Monthly</option>
                <option>Yearly</option>
                <option>Half Yearly</option>
              </select>
              {formik.touched.period && formik.errors.period && (
                <div className="text-red-500">{formik.errors.period}</div>
              )}
            </div>
          </div>

          <label className="w-1/2">Months :</label>
          <div className="flex flex-col flex-wrap h-24 justify-start items-start ml-52">
            {[
              'apr',
              'may',
              'jun',
              'jul',
              'aug',
              'sep',
              'oct',
              'nov',
              'dec',
              'jan',
              'feb',
              'mar',
            ].map((month, idx) => (
              <div key={idx}>
                <Field type="checkbox" name="months" value={month} />
                <label> {month.toUpperCase()}</label>
              </div>
            ))}
          </div>
          {formik.touched.months && formik.errors.months && (
            <div className="text-red-500">{formik.errors.months}</div>
          )}

          <div className="w-full flex justify-center items-center">
            <button
              className="w-1/2  m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
              type="submit"
              onClick={formik.handleSubmit}
            >
              Submit
            </button>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default AddFeeStructure;

// {
/**
   * 
   * <form className="w-1/2 mx-auto text-gray-700 ">
          
        </form>
   * 
   * 
   * * */
// }
