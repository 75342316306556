import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadClass } from 'redux/class/class.action';
import { useFormik, FormikProvider, Field, Form } from 'formik';
import { loadFeeStructure } from 'redux/feeStructures/feeStructure.action';
import { loadStudent } from 'redux/students/students.action';
import Spinner from 'components/spinner';
import { useReactToPrint } from 'react-to-print';
import SchoolBanner from 'assets/banner.png';

const AdmitCard = () => {
  const { classes, feeStructures, students } = useSelector((state) => state);
  const [stdFeeDues, setStdFeeDues] = useState([]);
  const [stdPaidList, setStdPaidList] = useState([]);
  const [isSecondBtn, setIsSecondBtn] = useState(false);
  const [loading, setLoading] = useState(true);
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,

    // pageStyle: `
    //     @page {
    //         size: A4;
    //         margin: 0;
    //     }
    // `,
  });

  let allMonths = [
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'jan',
    'feb',
    'mar',
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    !classes.length ? dispatch(loadClass()) : setLoading(false);
    !feeStructures.length ? dispatch(loadFeeStructure()) : setLoading(false);
    !students.length ? dispatch(loadStudent()) : setLoading(false);
  }, [classes.length, students.length, feeStructures.length, dispatch]);

  const formik = useFormik({
    initialValues: {
      _class: '',
      months: [],
    },
    onSubmit: (values) => {
      let filterMonths = allMonths.filter((mon) => values.months.includes(mon));
      let filterStudents = students.filter(
        (std) => std._class?._id === values._class
      );

      const myFeeStructures =
        feeStructures &&
        feeStructures.filter((fStruct) => {
          return fStruct._class._id === values._class;
        });

      const nArr = filterStudents.map((std) => {
        let duesMonths = filterMonths.filter(
          (mon) => !std.paidMonths.includes(mon)
        );

        let test = myFeeStructures.map((mFs) =>
          mFs.months.filter((mfs) => duesMonths.includes(mfs))
        );
        const total = myFeeStructures.map(
          (ele, idx) => ele.amount * test[idx].length
        );
        const totalFeeWithoutOldBal = total.reduce(
          (pre, curr) => pre + curr,
          0
        );

        return {
          ...std,
          duesMonths: duesMonths,
          dues: totalFeeWithoutOldBal,
        };
      });

      if (isSecondBtn) {
        const paidList = nArr.filter((std) => !std.dues > 0);
        setStdPaidList(paidList);
      } else {
        const duesList = nArr.filter((std) => std.dues > 0);
        setStdFeeDues(duesList);
      }
    },

    validate: (values) => {
      let errors = {};
      if (!values._class) errors._class = 'Required';
      if (!values.months.length) errors.months = 'Required';
      return errors;
    },
  });

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full p-10 pt-5">
          <h1 className="w-full pb-5 text-center font-medium font-serif text-2xl text-green-500 ">
            Admit Card ClassWise
          </h1>
          <FormikProvider value={formik}>
            <Form className="w-full mb-10">
              <div className="flex">
                <label className="mr-5">Months :</label>
                <div className="flex-1 flex flex-row flex-wrap w-full justify-start">
                  {allMonths.map((month, idx) => (
                    <div key={idx} className="ml-5">
                      <Field type="checkbox" name="months" value={month} />
                      <label> {month.toUpperCase()}</label>
                    </div>
                  ))}
                  {formik.touched.months && formik.errors.months && (
                    <div className="text-red-500">{formik.errors.months}</div>
                  )}
                </div>
              </div>

              <div className="flex flex-row items-center mt-5">
                <label className="w-1/6">Class :</label>
                <div className="w-full space-x-8">
                  <select
                    name="_class"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values._class}
                    className=" border-gray-300 w-1/4 flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
                  >
                    <option value="Select">Select</option>
                    {classes &&
                      classes.map((cl) => (
                        <option value={cl._id} key={cl._id}>
                          {cl._class}
                        </option>
                      ))}
                  </select>

                  <button
                    type="button"
                    className="px-4 py-2  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
                    onClick={() => {
                      formik.handleSubmit();
                      setIsSecondBtn(true);
                    }}
                  >
                    Get Paid Admit Card
                  </button>
                  <button
                    type="button"
                    className="px-4 py-2  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-green-900"
                    onClick={() => {
                      formik.handleSubmit();
                      setIsSecondBtn(false);
                    }}
                  >
                    Get Un-Paid Admit Card
                  </button>
                  <button
                    onClick={handlePrint}
                    className="ml-96 mt-5 p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
                  >
                    Print Admit Card
                  </button>
                  {formik.touched._class && formik.errors._class && (
                    <div className="text-red-500">{formik.errors._class}</div>
                  )}
                </div>
              </div>
            </Form>
          </FormikProvider>
          <div ref={ref} className="grid grid-flow-row  grid-cols-2 gap-x-4">
            {!isSecondBtn
              ? stdFeeDues.map((std, idx) => <ViewList std={std} idx={idx} />)
              : stdPaidList.map((std, idx) => <ViewList std={std} idx={idx} />)}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdmitCard;

const ViewList = ({ std, idx }) => {
  const ref = useRef();
  const [isSinglePrint, setIsSinglePrint] = useState(false);
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const paperSizeA4 = {
    height: '297mm',
    width: '210mm',
    padding: '0.5cm',
  };
  useLayoutEffect(() => {
    if (isSinglePrint) {
      ref.current.style.width = '50%';
      handlePrint();
      setIsSinglePrint(false);
    }
    return () => {
      ref.current.style.width = '100%';
    };
  }, [isSinglePrint, handlePrint]);

  return (
    <div
      ref={ref}
      className={`bg-white relative`}
      style={{
        height: '99mm',
        margin: 0,
        padding: 0,
        boxSizing: 'border-box',
      }}
      key={idx}
    >
      <div
        onClick={() => {
          setIsSinglePrint(true);
        }}
        className="print:hidden absolute top-5 right-5 underline text-green-700 font-medium cursor-pointer"
      >
        Print
      </div>
      <img
        src={SchoolBanner}
        alt="school-logo"
        className="h-14 mx-auto my-auto"
      />
      <div className="text-center text-base font-medium bg-gray-200 py-1">
        Admit Card || Session 2022-23
      </div>
      <ul className="p-4 divide-y-2 text-sm">
        <li className="flex justify-start gap-1">
          <span>Admission No:</span>
          <span>{std.admissionNo}</span>
        </li>
        <li className="flex justify-start gap-1">
          <span>Total fee Due:</span>
          <span>{std.dues}</span>
        </li>
        <li className="flex justify-start gap-1">
          <span>Student's Name:</span>
          <span>{std.studentsName}</span>
        </li>
        <li className="flex justify-start gap-1">
          <span>Class:</span>
          <span>{std._class?._class}</span>
        </li>
        <li className="flex justify-start gap-1">
          <span>Father's Name:</span>
          <span>{std.fathersName}</span>
        </li>
        <li className="flex justify-start gap-1">
          <span>Date Of Birth:</span>
          <span>{std.dob}</span>
        </li>
        <li className="flex justify-start gap-1">
          <span>Mobile No:</span>
          <span>{std.mobile}</span>
        </li>
        <li className="flex justify-around mt-8">
          <span>Class Teacher</span>
          <span>Principal</span>
        </li>
      </ul>
    </div>
  );
};
