import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const modalRoot = document.getElementById('modal');

const Modal = ({ children, handleClick, title, className }) => {
  const elRef = useRef(null);
  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }

  useEffect(() => {
    modalRoot.appendChild(elRef.current);
    return () => modalRoot.removeChild(elRef.current);
  }, []);

  return createPortal(
    <div
      style={{ minHeight: '20vh', minWidth: '20vw' }}
      className={`${className} fixed   z-50 bg-white  rounded-lg shadow-xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 `}
    >
      <p
        className={`font-bold p-2 w-full bg-green-500 text-white rounded-t-lg`}
      >
        {title}
        <span>
          <button
            className="absolute top-0 right-0  font-bold p-2 px-5 hover:bg-red-500 text-red-50  rounded-tr-lg "
            onClick={handleClick}
          >
            X
          </button>
        </span>
      </p>

      {children}
    </div>,

    elRef.current
  );
};

export default Modal;
