import { setCurrentUser } from 'redux/user/user.action';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Banner from 'assets/banner.png';

const AppBar = ({ ...otherProps }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <div
      className="w-full  h-14  bg-white  flex flex-row items-center justify-end border-b-1 border-gray-100 shadow-sm"
      {...otherProps}
    >
      <img alt={'Sc Banner'} src={Banner} className="p-5 w-1/4 flex-shrink-0" />

      <div className="mr-5">
        <button
          className="bg-red-500 rounded-lg p-2 text-white hover:bg-red-600"
          onClick={(e) => {
            sessionStorage.removeItem('sfms-user');
            dispatch(setCurrentUser(null));
            history.push('/login');
          }}
        >
          LogOut
        </button>
      </div>
    </div>
  );
};

export default AppBar;
