import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import Login from 'components/login';
import WrapperComponent from 'components/wrapper-components';
import PrivateRoute from 'components/privateRoute';
import { useSelector } from 'react-redux';
import Students from 'components/students/students';
import AddStudent from 'components/students/addstudent';
import Classes from 'components/class/classes';
import AddClass from 'components/class/addClass';
import FeeHeads from 'components/feeHeads/feeHeads';
import AddFeeHead from 'components/feeHeads/addFeeHead';
import Fees from 'components/fees/fees';
import PayFee from 'components/fees/payFee';
import FeeStructures from 'components/feeStructures/feeStructures';
import Dashboard from 'components/dashboard/dashboard';
import AddFeeStructure from 'components/feeStructures/addFeeStructure';
import PrintReciept from 'components/fees/printRec';
import ViewPaidFee from 'components/fees/viewPaidFee';
import ViewFees from 'components/fees/viewFees';
import DuesList from 'components/fees/duesList';
import EditStudent from 'components/students/editStudent';
import BusRoutes from 'components/busRoutes/busRoutes';
import TransportFS from 'components/transportFS/transportFS';
import TransportFees from 'components/transportFees/transportFees';
import PayTransportFee from 'components/transportFees/payTransportFee';
import PrintTransportReciept from 'components/transportFees/printTransportRec';
import ViewTransportFees from 'components/transportFees/viewTransportFees';
import TransportDuesList from 'components/transportFees/transportDuesList';
import ViewTransportPaidFee from 'components/transportFees/viewTransportPaidFee';
import AdmitCard from 'components/admitCard';
import Van from 'components/van/van';
import TransportDuesListVanWise from 'components/transportFees/transportDuesListVanWise';

const Routing = () => {
  const { currentUser } = useSelector((state) => state.user);

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/login"
          render={() => (currentUser ? <Redirect to="/" /> : <Login />)}
        />
        <PrivateRoute>
          <WrapperComponent>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/students" component={Students} />
            <Route exact path="/student/add" component={AddStudent} />
            <Route exact path="/student/edit" component={EditStudent} />
            <Route exact path="/classes" component={Classes} />
            <Route exact path="/class/add" component={AddClass} />
            <Route exact path="/feeHeads" component={FeeHeads} />
            <Route exact path="/routes" component={BusRoutes} />
            <Route exact path="/vans" component={Van} />

            <Route exact path="/feeHead/add" component={AddFeeHead} />
            <Route exact path="/feeStructures" component={FeeStructures} />
            <Route
              exact
              path="/transportFeeStructures"
              component={TransportFS}
            />
            <Route exact path="/feeStructure/add" component={AddFeeStructure} />
            <Route exact path="/fees" component={Fees} />
            <Route exact path="/transportFees" component={TransportFees} />
            <Route exact path="/admitCard" component={AdmitCard} />
            <Route
              exact
              path="/transportFees/view"
              component={ViewTransportFees}
            />
            <Route exact path="/fees/view" component={ViewFees} />
            <Route exact path="/fees/dues" component={DuesList} />
            <Route
              exact
              path="/transportFees/dues"
              component={TransportDuesList}
            />
            <Route
              exact
              path="/transportFees/dues-van-wise"
              component={TransportDuesListVanWise}
            />
            <Route exact path="/fees/pay/:id" component={PayFee} />
            <Route
              exact
              path="/transportFees/pay/:id"
              component={PayTransportFee}
            />
            <Route
              exact
              path="/transportFees/print/:id"
              component={PrintTransportReciept}
            />
            <Route exact path="/fees/print/:id" component={PrintReciept} />
            <Route exact path="/fees/view/:id" component={ViewPaidFee} />
            <Route
              exact
              path="/transportFees/view/:id"
              component={ViewTransportPaidFee}
            />
          </WrapperComponent>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};

export default Routing;
