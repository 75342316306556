import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ViewStudent from 'components/students/viewStudentList';
import { loadStudent } from 'redux/students/students.action';
import { loadClass } from 'redux/class/class.action';
import { useReactToPrint } from 'react-to-print';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import { exportExcel } from 'utils/exportExcel';

const Students = () => {
  const { students, classes } = useSelector((state) => state);
  const [cls, setCls] = useState('');
  const ref = useRef();

  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  let options = classes.map((cl) => {
    return { name: cl._class, value: cl._id };
  });

  options.unshift({ name: 'All', value: '' });

  useEffect(() => {
    !students.length > 0 && dispatch(loadStudent());
    !classes.length > 0 && dispatch(loadClass());
  }, [classes.length, students.length, dispatch]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  const filterStudent = (stdns) => {
    if (searchQuery !== '') {
      return stdns.filter(
        ({ studentsName, fathersName, address, mothersName, mobile, dob }) => {
          return Object.values({
            studentsName,
            fathersName,
            address,
            mothersName,
            mobile,
            dob,
          })
            .join(' ')
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      );
    }

    return stdns;
  };

  const history = useHistory();

  return (
    <div className="flex flex-col">
      <div className="">
        <div className="">
          <div className="mt-5 flex justify-center gap-5">
            <button
              className="border border-green-500 rounded-lg p-2 text-green-500 hover:bg-green-500 hover:border-opacity-0 hover:text-white"
              onClick={(e) => {
                history.push('student/add');
              }}
            >
              Student Admission
            </button>
            <button
              className="border border-green-500 rounded-lg p-2 text-green-500 hover:bg-green-500 hover:border-opacity-0 hover:text-white"
              onClick={handlePrint}
            >
              Print Record
            </button>
            <button
              onClick={() => {
                exportExcel({
                  data: filterStudent(
                    students.filter((student) =>
                      cls !== '' ? student._class._id === cls : true
                    )
                  ).map((std, idx) => {
                    return {
                      'S/N': idx + 1,
                      'Admission Number': std.admissionNo,
                      'Student Name': std.studentsName,
                      'Mothers Name': std.mothersName,
                      'S/D/O': std.fathersName,
                      Class: std._class?._class,
                      Mobile: std.mobile,
                      Address: std.address,
                      Transport: std.transport ? 'Yes' : 'No',
                      'Transport Route': std.route?.route,
                      'Fee Paid Months': std.paidMonths
                        .map((month) => month)
                        .join(', '),
                      'Transport Fee Paid Months': std.transportMonths
                        .map((month) => month)
                        .join(', '),
                      'Old Balance': std.oldBalance,
                    };
                  }),
                  fileName: `Students.xlsx`,
                });
              }}
              className="p-2 mx-5 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
            >
              Download Excel
            </button>
          </div>
          <div className="w-1/2 flex p-2 justify-center items-center">
            <label className="mr-5 ml-5">Select Class: </label>
            <div className="flex-1">
              <SelectSearch
                name="route"
                options={options}
                value={cls}
                onChange={setCls}
                search
                filterOptions={fuzzySearch}
                placeholder="Search class"
              />
            </div>
          </div>
        </div>
        <div className="px-5">
          <input
            type="search"
            placeholder="Search by StudentsName or Fathers Name or Mothers Name or Mobile or Address "
            className=" border-gray-300   w-full flex-grow rounded-lg border shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div
          ref={ref}
          className="m-5 shadow overflow-hidden border-b border-gray-300 sm:rounded-lg"
        >
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 ">
              <tr className="text-left">
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Admn No.
                </th>
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Father's Name
                </th>
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Mother's Name
                </th>
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Class
                </th>
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Mobile
                </th>
                <th
                  scope="col"
                  className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {students &&
                filterStudent(
                  students.filter((student) =>
                    cls !== '' ? student._class._id === cls : true
                  )
                ).map((student) => (
                  <ViewStudent student={student} key={student._id} />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Students;
