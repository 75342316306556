import { useEffect, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ViewVan from 'components/van/viewVan';
import { loadBusRoutes } from 'redux/busRoutes/busRoutes.action';
import AddBusRotes from './addVan';
import { loadVan } from 'redux/van/van.action';

const Van = () => {
  const busRoutes = useSelector((state) => state.busRoutes);
  const vans = useSelector((state) => state.van);
  const [showModel, setShowModel] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    !busRoutes.length > 0 && dispatch(loadBusRoutes());
  }, [busRoutes.length, dispatch]);

  useEffect(() => {
    !vans.length > 0 && dispatch(loadVan());
  }, [vans.length, dispatch]);

  return (
    <div className="flex flex-col">
      <div className="m-5 flex justify-center gap-5">
        <button
          className="border border-green-500 rounded-lg p-2 text-green-500 hover:bg-green-500 hover:border-opacity-0 hover:text-white"
          onClick={(e) => setShowModel(true)}
        >
          Add Van
        </button>
      </div>
      <div className="overflow-x-auto p-5">
        <div className="  align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 ">
                <tr className="text-left">
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Sl No.
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Van Number
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Capacity
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Driver Name
                  </th>

                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Driver Phone
                  </th>

                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Routes
                  </th>

                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {vans &&
                  vans.map((van, idx) => {
                    return <ViewVan van={van} key={idx} idx={idx} />;
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showModel && <AddBusRotes handleClick={() => setShowModel(false)} />}
    </div>
  );
};

export default memo(Van);
