import React, { useState } from 'react';
import BgImage from '../assets/background-login.jpg';

import { useHistory } from 'react-router';
import axios from 'axios';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { setCurrentUser } from 'redux/user/user.action';

const Login = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [userId, setUserId] = useState('');
  const [pass, setPass] = useState('');
  const [err, setErr] = useState(false);
  const [loginErr, setLoginErr] = useState(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      const loading = toast.loading('Please wait...');

      if (!userId) setErr(true);
      if (!pass) setErr(true);

      if (userId && pass) {
        const res = await axios({
          method: 'post',
          url: process.env.REACT_APP_SERVER_URL + 'login',
          data: {
            userId,
            pass,
          },
        });

        if (res.data.status === 'fail') {
          setLoginErr(true);
          toast.update(loading, {
            render: 'Login Failed',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
        }

        if (res.data.status === 'success') {
          toast.update(loading, {
            render: 'Login Success',
            type: 'success',
            isLoading: false,
            autoClose: 3000,
          });
          dispatch(setCurrentUser(res.data.result));
          sessionStorage.setItem('sfms-user', JSON.stringify(res.data.result));
          history.push('/');
        }
      }
    } catch (error) {
      setLoginErr(true);
    }
  };

  return (
    <div
      className="flex justify-center items-center h-screen w-screen "
      style={{
        backgroundImage: `url(${BgImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundBlendMode: 'multiply',
        backgroundColor: 'hsl(200, 100%, 30%)',
      }}
    >
      <form className="h-96 w-1/3 relative bg-white bg-opacity-40  rounded-md flex flex-col justify-center items-center">
        {err && (
          <p className="bg-yellow-400 p-3 absolute w-full rounded-md text-center top-0 text-white">
            please enter the user id and password
          </p>
        )}
        {loginErr && (
          <p className="bg-red-500 p-3 absolute w-full rounded-md text-center top-0 text-white">
            login failed please recheck your login credential
          </p>
        )}
        <label htmlFor="username" className="w-1/2">
          User ID
        </label>
        <input
          type="text"
          name="userid"
          value={userId}
          className="form-input px-4 py-3 rounded-md w-1/2 border-green-500 border-2"
          onChange={(e) => setUserId(e.target.value)}
        />
        <label htmlFor="password" className="w-1/2 mt-5">
          Password
        </label>
        <input
          type="password"
          name="pass"
          value={pass}
          className="form-input px-4 py-3 rounded-md w-1/2 border-green-500 border-2"
          onChange={(e) => setPass(e.target.value)}
        />
        <button
          className="bg-green-500 hover:bg-green-700  text-white p-2 mt-5 rounded-md w-1/2"
          onClick={handleSubmit}
        >
          Log In
        </button>
      </form>
    </div>
  );
};

export default Login;
