import { useEffect, memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ViewFeeHead from 'components/feeHeads/viewFeeHead';
import { loadFeeHead } from 'redux/feeHead/feeHead.action';

const FeeHeads = () => {
  const feeHeads = useSelector((state) => state.feeHeads);
  const dispatch = useDispatch();
  useEffect(() => {
    !feeHeads.length > 0 && dispatch(loadFeeHead());
  }, [feeHeads, dispatch]);

  const history = useHistory();

  return (
    <div className="flex flex-col">
      <div className="m-5 flex justify-center gap-5">
        <button
          className="border border-green-500 rounded-lg p-2 text-green-500 hover:bg-green-500 hover:border-opacity-0 hover:text-white"
          onClick={(e) => {
            history.push('feeHead/add');
          }}
        >
          Add Fee Head
        </button>
      </div>
      <div className="overflow-x-auto p-5">
        <div className="  align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 ">
                <tr className="text-left">
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Sl No.
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Fee Head
                  </th>

                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {feeHeads &&
                  feeHeads.map((feeHead, idx) => {
                    return (
                      <ViewFeeHead feeHead={feeHead} key={idx} idx={idx} />
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FeeHeads);
