import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FaEdit, FaEye, FaTrash } from 'react-icons/fa';
import ViewStudentDetail from './viewStudentDetails';
import DeleteStudent from './deleteStudent';

const ViewStudent = ({ student }) => {
  const history = useHistory();
  const [showModelView, setShowModelView] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);

  return (
    <tr key={student.studentsName} className="text-left hover:bg-green-50">
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">
          {student.admissionNo}
        </div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm text-gray-900">{student.studentsName}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm text-gray-500">{student.fathersName}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm text-gray-500">{student.mothersName}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm text-gray-900">{student._class._class}</div>
      </td>
      <td
        className=" px-2 whitespace-pre-wrap text-sm text-gray-500 truncate "
        style={{
          maxWidth: '100px',
        }}
      >
        {student.address}
      </td>
      <td className="px-2 whitespace-pre-wrap text-sm text-gray-500">
        {student.mobile}
      </td>
      <td className="px-2 whitespace-nowrap text-sm font-medium">
        <div className="">
          <button
            onClick={() => history.push('/student/edit', student)}
            className="p-2 m-1  rounded-md bg-green-500 hover:bg-green-600 text-white"
          >
            <FaEdit />
          </button>
          <button
            onClick={() => setShowModelView(true)}
            className="p-2 m-1 rounded-md bg-yellow-500 hover:bg-yellow-600 text-white"
          >
            <FaEye />
          </button>
          <button
            onClick={() => setShowDeleteModel(true)}
            className="p-2  m-1 rounded-md bg-red-500 hover:bg-red-600 text-white"
          >
            <FaTrash />
          </button>
        </div>

        {showModelView && (
          <ViewStudentDetail
            student={student}
            handleClick={() => setShowModelView(false)}
          />
        )}

        {showDeleteModel && (
          <DeleteStudent
            student={student}
            handleClick={() => setShowDeleteModel(false)}
          />
        )}
      </td>
    </tr>
  );
};

export default ViewStudent;
