import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import sidebarReducer from './sidebar/sidebar.reducer';
import studentsReducer from './students/students.reducer';
import classReducer from './class/class.reducer';
import feeHeadReducer from './feeHead/feeHead.reducer';
import feeStructureReducer from './feeStructures/feeStructure.reducer';
import feeReducer from './fees/fees.reducer';
import busRoutesReducer from './busRoutes/busRoutes.reducer';
import transportFSReducer from './transportFS/transportFS.reducer';
import vanReducer from './van/van.reducer';

const rootReducer = combineReducers({
  user: userReducer,
  sidebar: sidebarReducer,
  students: studentsReducer,
  classes: classReducer,
  feeHeads: feeHeadReducer,
  feeStructures: feeStructureReducer,
  fees: feeReducer,
  busRoutes: busRoutesReducer,
  transportFS: transportFSReducer,
  van: vanReducer,
});

export default rootReducer;
