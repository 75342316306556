import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { addFeeHead } from 'redux/feeHead/feeHead.action';

const AddFeeHead = () => {
  const [feeHead, setFeeHead] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (feeHead !== '') {
      dispatch(addFeeHead(feeHead));
      toast.info('Added Record');
      history.replace('/feeHeads');
    }
  };

  return (
    <div className="w-full">
      <div className="w-1/2 mx-auto text-center text-lg font-semibold rounded-lg shadow-sm text-green-900 p-2 m-4 bg-white border-b-2 border-green-900">
        Add Fee Head
      </div>
      <form className="w-1/2 mx-auto text-gray-700 ">
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Fee Head Name:</label>
          <input
            type="text"
            name="feeHead"
            value={feeHead}
            onChange={(e) => setFeeHead(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>

        <div className="flex flex-row mt-2 ">
          <button
            className="w-full m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
          <button
            className="w-full m-5  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-red-900"
            onClick={() => history.replace('/feeHeads')}
          >
            Go Back
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddFeeHead;
