import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ViewFeeStructure from 'components/feeStructures/viewFeeStructure';

import { loadFeeStructure } from 'redux/feeStructures/feeStructure.action';
import { loadClass } from 'redux/class/class.action';

const FeeStructures = () => {
  const { feeStructures, classes } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [cls, setCls] = useState('');

  useEffect(() => {
    !feeStructures.length > 0 && dispatch(loadFeeStructure());
    !classes.length > 0 && dispatch(loadClass());
  }, [classes, feeStructures, dispatch]);

  const history = useHistory();

  return (
    <div className="flex flex-col">
      <div className="m-5 flex justify-center gap-5">
        <button
          className="border border-green-500 rounded-lg p-2 text-green-500 hover:bg-green-500 hover:border-opacity-0 hover:text-white"
          onClick={(e) => {
            history.push('feeStructure/add');
          }}
        >
          Add Fee Structure
        </button>
      </div>
      <div className="w-1/2 pl-5">
        <label className="mr-4">Select Class: </label>
        <select
          name="cls"
          value={cls}
          onChange={(e) => setCls(e.target.value)}
          className={` border-gray-300 my-2 w-1/4 flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500`}
        >
          <option>Select Class</option>
          {classes.map((cls) => {
            return (
              <option key={cls._id} value={cls._id}>
                {cls._class}
              </option>
            );
          })}
        </select>
      </div>
      <div className="overflow-x-auto p-5">
        <div className="  align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 ">
                <tr className="text-left">
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Sl No.
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Class
                  </th>

                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Fee Type
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Period
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {feeStructures &&
                  feeStructures
                    .filter((fStruct) => fStruct._class._id === cls)
                    .map((fStruct, idx) => (
                      <ViewFeeStructure
                        fStruct={fStruct}
                        idx={idx}
                        key={Math.random()}
                      />
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeeStructures;
