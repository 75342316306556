import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik, FormikProvider, Field, Form } from 'formik';
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { loadFeeStructure } from 'redux/feeStructures/feeStructure.action';
import Spinner from 'components/spinner';
import { upadateStudent } from 'redux/students/students.action';
import { toast } from 'react-toastify';

const PayFee = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const student = location.state;
  const feeStructures = useSelector((state) => state.feeStructures);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [rcpN, setRcpN] = useState('');

  const getFeeDB = useCallback(async () => {
    try {
      const res = await axios.get(
        process.env.REACT_APP_SERVER_URL + 'fees/last/1'
      );
      if (res.status === 200) {
        if (!res.data.result?.recieptNo) setRcpN(1);
        if (res.data.result?.recieptNo) setRcpN(res.data.result.recieptNo + 1);
      } else {
        toast.error('Error fetching reciept number');
      }
    } catch (error) {
      toast.error('Error fetching reciept number');
      toast.error('Please make sure you are connected to the internet');
      history.goBack();
    }
  }, [history]);

  useEffect(() => {
    //check if device is online
    !feeStructures.length > 0 && dispatch(loadFeeStructure());
    getFeeDB();
  }, [feeStructures, dispatch, getFeeDB]);

  let allMonths = [
    'apr',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'jan',
    'feb',
    'mar',
  ];

  let filterMonths = allMonths.filter(
    (mon) => !student.paidMonths.includes(mon)
  );

  const myFeeStructures =
    selectedMonths &&
    feeStructures.filter((fStruct) => {
      return (
        fStruct._class._id === student._class._id &&
        fStruct.months.some((mfs) => selectedMonths.includes(mfs))
      );
    });

  const payMonths =
    selectedMonths &&
    myFeeStructures.map((mFs) =>
      mFs.months.filter((mfs) => selectedMonths.includes(mfs))
    );

  const total =
    selectedMonths &&
    myFeeStructures.map((ele, idx) => ele.amount * payMonths[idx].length);

  const totalFeeWithoutOldBal =
    selectedMonths && total && total.reduce((pre, curr) => pre + curr, 0);
  const totalFee = student.oldBalance
    ? totalFeeWithoutOldBal + student.oldBalance
    : totalFeeWithoutOldBal;

  const formik = useFormik({
    initialValues: {
      paidMonths: selectedMonths,
      concession: 0,
      recievedFee: 0,
      paidDate: new Date(),
      academicSession: '2022-23',
      paymentMode: 'cash',
      remarks: '',
      student: student._id,
    },
    onSubmit: async (values) => {
      try {
        let netFee = totalFee - formik.values.concession;
        let dues =
          totalFee - formik.values.concession - formik.values.recievedFee;
        const res = await axios.post(
          process.env.REACT_APP_SERVER_URL + 'fees',
          {
            recieptNo: rcpN,
            totalFee,
            netFee,
            dues,
            ...values,
          }
        );

        dispatch(upadateStudent(res.data.result.student));
        toast.success('Student Fee Submited');
        formik.handleReset();
        const fees = res.data.result;
        history.replace(`/fees/print/${res.data.result._id}`, {
          student,
          fees,
          feeStruct: myFeeStructures,
          payMonths,
          total,
          oldBalance: student.oldBalance,
        });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleSubmit = () => {
    setSelectedMonths(formik.values.paidMonths);
  };

  return (
    <div className="w-full h-full bg-gray-50 p-10 pb-10 text-gray-600">
      <h1 className="font-semibold text-3xl mb-5 text-green-900 text-center">
        Pay Student Fee
      </h1>
      <FormikProvider value={formik}>
        <Form>
          <div>
            <div className=" w-full flex flex-col flex-wrap h-52">
              <div className="flex flex-row m-1 items-center w-1/2 ">
                <label className="w-1/2">Addmission No :</label>
                <input
                  type="text"
                  value={student.admissionNo}
                  disabled
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>
              <div className="flex flex-row m-1 items-center w-1/2">
                <label className="w-1/2">Students Name :</label>
                <input
                  type="text"
                  value={student.studentsName}
                  disabled
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>

              <div className="flex flex-row m-1 items-center w-1/2">
                <label className="w-1/2">Fathers Name :</label>
                <input
                  type="text"
                  value={student.fathersName}
                  disabled
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>

              <div className="flex flex-row m-1 items-center w-1/2">
                <label className="w-1/2">Class :</label>
                <input
                  type="text"
                  value={student._class._class}
                  disabled
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>
              <div className="flex flex-row m-1 items-center w-1/2">
                <label className="w-1/2">Mobile No :</label>
                <input
                  type="text"
                  value={student.mobile}
                  disabled
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>
              <div className="flex flex-row m-1 items-center w-1/2">
                <label className="w-1/2">Date :</label>
                <input
                  type="datetime-local"
                  name="paidDate"
                  value={formik.values.paidDate}
                  onChange={formik.handleChange}
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>
              <div className="flex flex-row m-1 items-center w-1/2">
                <label className="w-1/2">Reciept No :</label>
                <input
                  type="number"
                  name="recieptNo"
                  value={rcpN}
                  disabled
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>

              <div className="flex flex-row m-1 items-center w-1/2">
                <label className="w-1/2">Old Balance :</label>
                <input
                  type="number"
                  value={student.oldBalance}
                  disabled
                  name="oldBalance"
                  className=" border-gray-300   rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>
            </div>
            <div className="flex flex-row  items-center w-full bg-white p-2 shadow-sm rounded-md mb-2">
              <label className="w-1/4">Months :</label>
              <div className="flex flex-row flex-wrap w-full justify-start">
                {filterMonths &&
                  filterMonths.map((month) => {
                    return (
                      <div key={month} className="w-1/6">
                        <Field
                          type="checkbox"
                          name="paidMonths"
                          value={month}
                        />
                        <label> {month.toUpperCase()}</label>
                      </div>
                    );
                  })}
                <button
                  type="button"
                  className="px-5 py-1 m-2 bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
                  onClick={handleSubmit}
                >
                  OK
                </button>
              </div>
            </div>
            <div className="w-full font-mono bg-white shadow-sm rounded-md p-2 my-5">
              <div className="  font-semibold flex flex-row justify-between bg-gray-100 p-2">
                <p>Sl No.</p>
                <p className="flex-1 ml-5">Particulars</p>
                <p>Amount</p>
              </div>
              {myFeeStructures &&
                myFeeStructures.map((mFs, idx) => {
                  return (
                    <div className="flex flex-row justify-around  p-2">
                      <p>{idx + 1}</p>
                      <p className="flex-1 ml-16">
                        {mFs.feeHead.head}
                        <span className="ml-5">
                          {payMonths[idx].length} X {mFs.amount}
                        </span>
                      </p>
                      <p>{total[idx]}</p>
                    </div>
                  );
                })}
              {student.oldBalance > 0 && (
                <div className="font-semibold flex justify-between mx-5">
                  <p>Old Balance</p>
                  <p>{student.oldBalance}</p>
                </div>
              )}
              <div className="  font-semibold flex flex-row justify-between bg-gray-100 p-2">
                <p>Total Fee.</p>
                <p>{totalFee}</p>
              </div>
            </div>
            <div className="bg-white shadow-sm rounded-md mt-2 p-5">
              <div className="flex flex-row  justify-around  items-end flex-wrap text-gray-70">
                <div className="w-1/4 flex flex-col justify-around items-start">
                  <label>Concession: </label>
                  <Field
                    type="text"
                    name="concession"
                    onChange={formik.handleChange}
                    value={formik.values.concession}
                    placeholder="enter consession amt"
                    className=" border-gray-300 rounded-lg border px-3 py-2 shadow-sm "
                  />
                </div>
                <div className="w-1/4 flex flex-col justify-around items-start">
                  <label>Net Fee: </label>
                  <Field
                    type="text"
                    name="netFee"
                    value={totalFee - formik.values.concession}
                    onChange={formik.handleChange}
                    disabled
                    className=" border-gray-300 rounded-lg border px-3 py-2 shadow-sm "
                  />
                </div>
                <div className="w-1/4 flex flex-col justify-around items-start">
                  <label>Recieved Fee: </label>
                  <Field
                    type="number"
                    name="recievedFee"
                    value={formik.values.recievedFee}
                    onChange={formik.handleChange}
                    className=" border-gray-300 rounded-lg border px-3 py-2 shadow-sm "
                  />
                </div>
                <div className="w-1/4 flex flex-col justify-around items-start">
                  <label>Balance : </label>
                  <Field
                    type="number"
                    value={
                      totalFee -
                      formik.values.concession -
                      formik.values.recievedFee
                    }
                    disabled
                    className=" border-gray-300 rounded-lg border px-3 py-2 shadow-sm "
                  />
                </div>
              </div>
              <div className="p-5 space-x-5 flex flex-row justify-start items-center">
                <div className="w-1/4 flex flex-col justify-around items-start">
                  <span>Paymet Mode: </span>
                  <select
                    name="paymentMode"
                    onChange={formik.handleChange}
                    value={formik.values.paymentMode}
                    className="border-gray-300 rounded-lg border w-1/2  py-2 shadow-sm"
                  >
                    <option value="cash">Cash</option>
                    <option value="online">Online</option>
                  </select>
                </div>
                <label>Remarks: </label>
                <Field
                  type="text"
                  name="remarks"
                  value={formik.values.remarks}
                  onChange={formik.handleChange}
                  className=" w-full border-gray-300 rounded-lg border px-3 py-2 shadow-sm "
                />
              </div>
              {formik.isSubmitting ? (
                <Spinner />
              ) : (
                <button
                  onClick={formik.handleSubmit}
                  className="p-2 bg-green-500 px-5 text-white  rounded-md hover:bg-green-600"
                >
                  Submit Fee
                </button>
              )}
            </div>
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default PayFee;
