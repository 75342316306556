import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Model from 'components/model.component';
import { addVan, editVan } from 'redux/van/van.action';
import { toast } from 'react-toastify';
import Select from 'react-select';
const AddVan = ({ van, handleClick }) => {
  const dispatch = useDispatch();
  const [routes, setRoutes] = useState(
    van?.routes?.length
      ? van?.routes.map((v) => {
          return { value: v._id, label: v.route };
        })
      : []
  );

  const initialValues = van
    ? {
        vanNumber: van?.vanNumber,
        capacity: van?.capacity,
        driverName: van?.driverName,
        driverMobile: van?.driverMobile,
      }
    : {
        vanNumber: '',
        capacity: '',
        driverName: '',
        driverMobile: '',
      };

  const [vanData, setVanData] = useState(initialValues);
  const { busRoutes } = useSelector((state) => state);
  const busRouteOptions = busRoutes.map((bR) => {
    return { label: bR.route, value: bR._id };
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      vanData.vanNumber !== '' &&
      vanData.capacity !== '' &&
      vanData.driverName !== '' &&
      vanData.driverMobile !== '' &&
      routes.length > 0
    ) {
      dispatch(
        van
          ? editVan(
              {
                ...vanData,
                routes: routes.map((r) => r.value),
              },
              van?._id
            )
          : addVan({
              ...vanData,
              routes: routes.map((r) => r.value),
            })
      );
      handleClick();
    } else {
      toast('Please fill all the fields', {
        position: 'top-center',
        autoClose: 5000,
      });
    }
  };

  const handleChange = (e) => {
    setVanData({ ...vanData, [e.target.name]: e.target.value });
  };

  const clearForm = (e) => {
    e.preventDefault();
    setVanData({
      routes: [],
      vanNumber: '',
      capacity: '',
      driverName: '',
      driverMobile: '',
    });
  };

  return (
    <Model
      handleClick={handleClick}
      title={`${van ? 'Edit' : 'Add'} Van Master`}
      className="w-1/2 "
    >
      <form className="w-2/3 mt-10 mx-auto text-gray-700 ">
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Van Number:</label>
          <input
            type="text"
            name="vanNumber"
            value={vanData.vanNumber}
            onChange={handleChange}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Seat Capacity:</label>
          <input
            type="text"
            name="capacity"
            value={vanData.capacity}
            onChange={handleChange}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Driver Name:</label>
          <input
            type="text"
            name="driverName"
            value={vanData.driverName}
            onChange={handleChange}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Driver Mobile:</label>
          <input
            type="text"
            name="driverMobile"
            value={vanData.driverMobile}
            onChange={handleChange}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2"> Routes</label>
          <Select
            options={busRouteOptions}
            isMulti
            name="routes"
            value={routes}
            onChange={setRoutes}
            className="w-full py-2"
            styles={{
              control: (base, state) => ({
                ...base,
                borderColor: 'transparent',
                minWidth: '100%',
                padding: '0.5rem',
                '&:hover': {
                  borderColor: 'rgb(34 197 94)',
                },
              }),
            }}
          />
        </div>

        <div className="flex flex-row mt-2 ">
          <button
            className="w-full m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
          <button
            type="button"
            className={`${
              van ? 'pointer-events-none' : ''
            } w-full m-5  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-red-900`}
            onClick={clearForm}
          >
            Reset
          </button>
        </div>
      </form>
    </Model>
  );
};

export default AddVan;
