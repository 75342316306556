import { busRoutesActionTypes } from './busRoutes.actionTypes';

const INITIAL_STATE = [];

const busRoutesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case busRoutesActionTypes.LOAD_BUS_ROUTES:
      return action.payload;
    case busRoutesActionTypes.ADD_BUS_ROUTES:
      return [...state, action.payload];
    default:
      return state;
  }
};

export default busRoutesReducer;
