import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { loadTransportFS } from 'redux/transportFS/transportFS.action';
import { loadBusRoutes } from 'redux/busRoutes/busRoutes.action';
import ViewTransportFS from './viewTransportFS';
import AddTransportFS from 'components/transportFS/addTransportFS';

const TransportFS = () => {
  const { transportFS, busRoutes } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showModel, setShowModel] = useState(false);

  useEffect(() => {
    !transportFS.length > 0 && dispatch(loadTransportFS());
    !busRoutes.length > 0 && dispatch(loadBusRoutes());
  }, [busRoutes.length, transportFS.length, dispatch]);

  return (
    <div className="flex flex-col">
      <div className="m-5 flex justify-center gap-5">
        <button
          className="border border-green-500 rounded-lg p-2 text-green-500 hover:bg-green-500 hover:border-opacity-0 hover:text-white"
          onClick={(e) => {
            setShowModel(true);
          }}
        >
          Add Transport Fee Structure
        </button>
      </div>

      <div className="overflow-x-auto p-5">
        <div className="  align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50 ">
                <tr className="text-left">
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Sl No.
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Route
                  </th>

                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Fee Type
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Period
                  </th>
                  <th
                    scope="col"
                    className=" py-5 px-2  text-xs font-medium text-gray-500  tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {transportFS &&
                  transportFS.map((transFS, idx) => (
                    <ViewTransportFS
                      transportFS={transFS}
                      idx={idx}
                      key={Math.random()}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showModel && <AddTransportFS handleClick={() => setShowModel(false)} />}
    </div>
  );
};

export default TransportFS;
