import { memo, useEffect, useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import EditVan from './editVan';

const ViewVan = ({ van, idx }) => {
  const [showEditModel, setShowEditModel] = useState(false);
  return (
    <tr key={van?._id} className="text-left hover:bg-green-50">
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{idx + 1}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{van?.vanNumber}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{van?.capacity}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{van?.driverName}</div>
      </td>

      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{van?.driverMobile}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">
          {van?.routes?.map(
            (route, idx) =>
              route.route + (idx === van?.routes.length - 1 ? '' : ', ')
          )}
        </div>
      </td>
      <td className="px-2 whitespace-nowrap text-sm font-medium">
        <div className="">
          <button
            onClick={() => setShowEditModel(true)}
            className="p-2 m-1  rounded-md bg-green-500 hover:bg-green-600 text-white"
          >
            <FaEdit />
          </button>
        </div>
      </td>
      {showEditModel && (
        <EditVan van={van} handleClick={() => setShowEditModel(false)} />
      )}
    </tr>
  );
};

export default memo(ViewVan);
