import { useEffect, useState } from 'react';
import axios from 'axios';
import Spinner from 'components/spinner';

const ViewRecentFee = () => {
  const [fee, setFee] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchFee = async () => {
    const res = await axios(`${process.env.REACT_APP_SERVER_URL}fees/limit/10`);
    setFee(res.data.result);
    setLoading(false);
  };

  useEffect(() => {
    fetchFee();
  }, [setLoading]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="font-mono text-gray-700 bg-white p-5 my-5 shadow-2xl rounded-lg">
          <h1 className="w-full text-center font-medium font-serif text-2xl text-green-700 ">
            Recent Payments
          </h1>

          <table>
            <tr className="text-left border bg-gray-50 text-sm">
              <th className="border  px-2">SN</th>
              <th className="border  px-2">Rec No.</th>
              <th className="border  px-2">Std. Name</th>
              <th className="border  px-2">S/D/O</th>
              <th className="border  px-2">Net Fee</th>
              <th className="border  px-2">Amount Paid</th>
              <th className="border  px-2">Bal.</th>
              <th className="border  px-2">Months</th>
              <th className="border  px-2">Mode</th>
              <th className="border  px-2">Date & Time</th>
              <th className="border  px-2">Remarks</th>
            </tr>
            {fee.map((ar, idx) => {
              const date = new Date(ar.paidDate);
              const [month, day, year] = [
                date.getMonth() + 1,
                date.getDate(),
                date.getFullYear(),
              ];
              const [hour, minutes, seconds] = [
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
              ];
              const dateAndTime = `${day}-${month}-${year} | ${hour}:${minutes}:${seconds}`;
              return (
                <tr className="text-left border text-sm ">
                  <td className="border  px-2">{idx + 1}</td>
                  <td className="border  px-2">{ar.recieptNo}</td>
                  <td className="border  px-2">{ar.student?.studentsName}</td>
                  <td className="border  px-2">{ar.student?.fathersName}</td>
                  <td className="border  px-2">{ar.netFee}</td>
                  <td className="border  px-2">{ar.recievedFee}</td>
                  <td className="border  px-2">{ar.dues}</td>
                  <td className="border  px-1 text-xs">
                    {ar.paidMonths.map((mon) => (
                      <span>{mon}, </span>
                    ))}
                  </td>
                  <td className="border  px-2">{ar.paymentMode}</td>
                  <td className="border  px-2">{dateAndTime}</td>
                  <td className="border  px-2">{ar.remarks}</td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
};

export default ViewRecentFee;
