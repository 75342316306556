import { feeHeadActionTypes } from './feeHead.actionTypes';
import axios from 'axios';

export const addFeeHead = (feeHead) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}feehead`,
      data: {
        head: feeHead,
      },
    }).then((res) => {
      dispatch({
        type: feeHeadActionTypes.ADD_FEE_HEAD,
        payload: res.data.result,
      });
    });
  };
};

export const removeFeeHead = (feeHead) => {
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_SERVER_URL}feehead/${feeHead._id}`,
    }).then((res) => {
      dispatch({
        type: feeHeadActionTypes.REMOVE_FEE_HEAD,
        payload: feeHead,
      });
    });
  };
};

export const loadFeeHead = () => {
  return (dispatch) => {
    axios(`${process.env.REACT_APP_SERVER_URL}feeHead`).then((res) => {
      dispatch({
        type: feeHeadActionTypes.LOAD_FEE_HEAD,
        payload: res.data.result,
      });
    });
  };
};
