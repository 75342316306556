import React, { useState } from 'react';
import Model from 'components/model.component';
import { useHistory } from 'react-router-dom';
import AddTransportFac from './addTransportFac';

const ViewStudentDetail = ({ student, handleClick }) => {
  const [showModel, setShowModel] = useState(false);
  const history = useHistory();
  return (
    <Model
      handleClick={handleClick}
      title={'View Student Details'}
      className="w-1/2"
    >
      <div className="flex flex-row justify-center mt-3 pr-3 w-full items-center  ">
        <button
          className="border border-gray-500 rounded-lg p-1 px-5 ml-2 text-gray-500 hover:border-gray-800 hover:text-gray-800"
          onClick={() => {
            history.push(`fees/pay/${student._id}`, student);
          }}
        >
          Pay Student Fee
        </button>
        {!student.transport && (
          <button
            className="border border-gray-500 rounded-lg p-1 px-5 ml-2 text-gray-500 hover:border-gray-800 hover:text-gray-800"
            onClick={() => setShowModel(true)}
          >
            Add Transport Fac.
          </button>
        )}
        {student.transport && (
          <button
            className="border border-gray-500 rounded-lg p-1 px-5 ml-2 text-gray-500 hover:border-gray-800 hover:text-gray-800"
            onClick={() => {
              history.push('transportFees/pay/' + student._id, student);
            }}
          >
            Pay Transport Fee.
          </button>
        )}
        <button
          className="border border-gray-500 rounded-lg p-1 px-5 ml-2 text-gray-500 hover:border-gray-800 hover:text-gray-800"
          onClick={() => {
            history.push('/fees/view/' + student._id, student);
          }}
        >
          View Fee
        </button>
        {student.transport && (
          <button
            className="border border-gray-500 rounded-lg p-1 px-5 ml-2 text-gray-500 hover:border-gray-800 hover:text-gray-800"
            onClick={() => {
              history.push('/transportFees/view/' + student._id, student);
            }}
          >
            View Transport Fee
          </button>
        )}
      </div>
      <div className="m-16">
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Addmission No :</label>
          <input
            type="text"
            value={student.admissionNo}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Students Name :</label>
          <input
            type="text"
            value={student.studentsName}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Gender :</label>
          <input
            type="text"
            value={student.gender}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Fathers Name :</label>
          <input
            type="text"
            value={student.fathersName}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Mothers Name :</label>
          <input
            type="text"
            value={student.mothersName}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Class :</label>
          <input
            type="text"
            value={student._class._class}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Mobile No :</label>
          <input
            type="text"
            value={student.mobile}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Address :</label>
          <input
            type="text"
            value={student.address}
            disabled
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
          />
        </div>
        {student.transport && (
          <React.Fragment>
            <div className="flex flex-row mt-2 items-center">
              <label className="w-1/2">Route :</label>
              <input
                type="text"
                value={student?.route?.route}
                disabled
                className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
              />
            </div>
            <div className="flex flex-row mt-2 items-center">
              <label className="w-1/2">Van Number :</label>
              <input
                type="text"
                value={student?.van?.vanNumber}
                disabled
                className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm "
              />
            </div>
          </React.Fragment>
        )}
      </div>
      {showModel && (
        <AddTransportFac
          handleClick={() => setShowModel(false)}
          student={student}
        />
      )}
    </Model>
  );
};

export default ViewStudentDetail;
