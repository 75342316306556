import { feeStructureActionType } from './feeStructure.actionTypes';

const INITIAL_STATE = [];

const feeStructureReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case feeStructureActionType.LOAD_FEE_STRUCTURE:
      return action.payload;
    case feeStructureActionType.ADD_FEE_STRUCTURE:
      return [...state, action.payload];
    case feeStructureActionType.UPDATE_FEE_STRUCTURE:
      return state.map((fS) =>
        fS._id === action.payload._id ? action.payload : fS
      );
    case feeStructureActionType.REMOVE_FEE_STRUCTURE:
      return removeFStruct(state, action.payload);
    default:
      return state;
  }
};

export default feeStructureReducer;

const removeFStruct = (fStruct, fStructToDel) => {
  const newData = fStruct.filter((fS) => fS._id !== fStructToDel._id);
  return newData;
};
