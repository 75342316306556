import { transportFSActionType } from './transportFS.actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';

export const addTransportFS = (TStruct) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}transportFeeStructure`,
      data: {
        ...TStruct,
      },
    }).then((res) => {
      dispatch({
        type: transportFSActionType.ADD_TRANSPORT_FS,
        payload: res.data.result,
      });
      toast.success('Added Transport Fee Structure');
    });
  };
};

export const updateTransportFS = (TStruct, id) => {
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}transportFeeStructure/${id}`,
      data: {
        ...TStruct,
      },
    }).then((res) => {
      dispatch({
        type: transportFSActionType.UPDATE_TRANSPORT_FS,
        payload: res.data.result,
      });
      toast.success('Transport Fee Structure Updated');
    });
  };
};

export const loadTransportFS = () => {
  return (dispatch) => {
    axios(`${process.env.REACT_APP_SERVER_URL}transportFeeStructure`).then(
      (res) => {
        dispatch({
          type: transportFSActionType.LOAD_TRANSPORT_FS,
          payload: res.data.result,
        });
        toast.success('Loaded Transport Fee Structure');
      }
    );
  };
};
