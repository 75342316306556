import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import Spinner from 'components/spinner';
import { useReactToPrint } from 'react-to-print';
const ViewPaidFee = () => {
  const location = useLocation();
  const history = useHistory();
  const [fee, setFee] = useState([]);
  const [loading, setLoading] = useState(true);
  const student = location.state;

  useEffect(() => {
    const fetchFee = async () => {
      const res = await axios(
        `${process.env.REACT_APP_SERVER_URL}fees/student/` + student._id
      );

      setFee(res.data.result);
      setLoading(false);
    };
    fetchFee();
  }, [student._id]);
  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div ref={ref} className="font-mono text-gray-700 px-5 py-5">
          <h1 className="w-full text-center font-medium font-serif text-2xl text-gray-700 p-1">
            Recent Payments Of {student.studentsName}
          </h1>
          <div className="p-1">
            <button
              onClick={handlePrint}
              className="ml-96 mt-5 p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
            >
              Print Record
            </button>
            <button
              onClick={() => history.replace('/')}
              className="ml-5 px-5 mt-5 p-2 text-red-700 border border-red-500  rounded-md hover:bg-gray-50"
            >
              Close
            </button>
          </div>

          <table>
            <tr className="text-left border bg-gray-50">
              <th className="border p-1 px-5">SN</th>
              <th className="border p-1 px-5">Reciept No</th>
              <th className="border p-1 px-5">Net Fee</th>
              <th className="border p-1 px-5">Amount Paid</th>
              <th className="border p-1 px-5">Balance</th>
              <th className="border p-1 px-5">Months</th>
              <th className="border p-1 px-5">Mode</th>
              <th className="border p-1 px-5">Date & Time</th>
              <th className="border p-1 px-5">Remarks</th>
            </tr>
            {fee.map((ar, idx) => {
              const date = new Date(ar.paidDate);
              const [month, day, year] = [
                date.getMonth() + 1,
                date.getDate(),
                date.getFullYear(),
              ];
              const [hour, minutes, seconds] = [
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
              ];
              const dateAndTime = `${day}-${month}-${year} | ${hour}:${minutes}:${seconds}`;
              return (
                <tr className="text-left border ">
                  <td className="border p-1 px-5">{idx + 1}</td>
                  <td className="border p-1 px-5">{ar.recieptNo}</td>
                  <td className="border p-1 px-5">{ar.netFee}</td>
                  <td className="border p-1 px-5">{ar.recievedFee}</td>
                  <td className="border p-1 px-5">{ar.dues}</td>
                  <td className="border p-1 px-5">
                    {ar.paidMonths.map((mon) => (
                      <span>{mon}, </span>
                    ))}
                  </td>
                  <td className="border p-1 px-5">{ar.paymentMode}</td>
                  <td className="border p-1 px-5">{dateAndTime}</td>
                  <td className="border p-1 px-5">{ar.remarks}</td>
                </tr>
              );
            })}
          </table>
        </div>
      )}
    </div>
  );
};

export default ViewPaidFee;
