import { vanActionTypes } from './van.actionTypes';

const INITIAL_STATE = [];

const vanReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case vanActionTypes.LOAD_VAN:
      return action.payload;
    case vanActionTypes.ADD_VAN:
      return [...state, action.payload];
    case vanActionTypes.EDIT_VAN:
      return state.map((v) => {
        if (v._id === action.payload._id) {
          return action.payload;
        }
        return v;
      });
    default:
      return state;
  }
};

export default vanReducer;
