import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { sideBarClose, sideBarOpen } from '../redux/sidebar/sidebar.reducer';

//icons
import { MdDashboard } from 'react-icons/md';
import {
  FaUsers,
  FaMoneyCheckAlt,
  FaBus,
  FaRoute,
  FaMoneyBill,
} from 'react-icons/fa';
import { RiListSettingsLine } from 'react-icons/ri';
import {
  MdClass,
  MdChevronLeft,
  MdChevronRight,
  MdExpandMore,
  MdExpandLess,
} from 'react-icons/md';
import { HiDocumentReport } from 'react-icons/hi';

const Sidebar = () => {
  const sideBarState = useSelector((state) => state.sidebar.sideBarState);
  const [masterMenuOpen, setMasterMenuOpen] = useState(false);
  const [examinationMenuOpen, setExaminationMenuOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const menu = [
    {
      title: 'Dashboard',
      icon: <MdDashboard />,
      active: false,
      link: '/',
    },
    {
      title: 'Students',
      icon: <FaUsers />,
      active: false,
      link: '/students',
    },

    {
      title: 'Collect Fee',
      icon: <FaMoneyCheckAlt />,
      active: false,
      link: '/fees',
    },
    {
      title: 'Transport Fee',
      icon: <FaMoneyBill />,
      active: false,
      link: '/transportFees',
    },
  ];

  const examinationMenu = [
    {
      title: 'Admit Card',
      icon: <HiDocumentReport />,
      active: false,
      link: '/admitCard',
    },
  ];

  const masterMenu = [
    {
      title: 'Classes',
      icon: <MdClass />,
      active: false,
      link: '/classes',
    },
    {
      title: 'Fee Heads',
      icon: <RiListSettingsLine />,
      active: false,
      link: '/feeHeads',
    },
    {
      title: 'Fee Structure',
      icon: <RiListSettingsLine />,
      active: false,
      link: '/feeStructures',
    },
    {
      title: 'Transport Routes',
      icon: <FaRoute />,
      active: false,
      link: '/routes',
    },
    {
      title: 'Van masters',
      icon: <FaBus />,
      active: false,
      link: '/vans',
    },

    {
      title: 'Transport Fee Struct.',
      icon: <FaBus />,
      active: false,
      link: '/transportFeeStructures',
    },
  ];

  const subMenu = [
    {
      title: 'Fee Dues List',
      icon: <HiDocumentReport />,
      active: false,
      link: '/fees/dues',
    },
    {
      title: 'View Fee Payments',
      icon: <HiDocumentReport />,
      active: false,
      link: '/fees/view',
    },
    {
      title: 'Transport Dues List Route Wise',
      icon: <HiDocumentReport />,
      active: false,
      link: '/transportFees/dues',
    },
    {
      title: 'Transport Dues List Van Wise',
      icon: <HiDocumentReport />,
      active: false,
      link: '/transportFees/dues-van-wise',
    },
    {
      title: 'View Transport Payments',
      icon: <HiDocumentReport />,
      active: false,
      link: '/transportFees/view',
    },
  ];
  return (
    <div
      className={`${
        sideBarState ? 'w-64' : 'w-16'
      } fixed h-full overflow-y-auto bg-gray-50 text-gray-600 border-r-1 border-gray-100  shadow-lg`}
    >
      <div className="text-2xl font-semibold text-white w-full  h-14 bg-green-500 p-3 flex items-center ">
        {sideBarState && 'SFMS'}
        {sideBarState ? (
          <MdChevronLeft
            onClick={() => dispatch(sideBarClose())}
            className="ml-auto text-3xl hover:bg-green-700 rounded-full cursor-pointer"
          />
        ) : (
          <MdChevronRight
            onClick={() => dispatch(sideBarOpen())}
            className="ml-auto text-3xl hover:bg-green-700 rounded-full cursor-pointer"
          />
        )}
      </div>

      <ul className="text-xl py-5 text-left ">
        {menu.map((menu, idx) => {
          return (
            <li
              key={idx}
              className={`${
                !sideBarState ? 'text-3xl p-4' : ''
              } p-2 hover:bg-green-100 transition-colors ${
                location.pathname === menu.link
                  ? 'bg-green-200 text-gray-700 font-semibold border-l-4 border-gray-700 transition-all'
                  : ''
              }`}
            >
              <Link
                to={menu.link}
                className=" 
                flex items-center justify-start w-full gap-4"
              >
                {menu.icon}
                {sideBarState && menu.title}
              </Link>
            </li>
          );
        })}
      </ul>
      <div>
        <p
          className="text-lg bg-gray-100 text-center py-2 border-t border-gray-200 cursor-pointer"
          onClick={() => setExaminationMenuOpen((pre) => !pre)}
        >
          {sideBarState && 'Examination'}
          {examinationMenuOpen && (
            <button onClick={() => setExaminationMenuOpen(false)}>
              <MdExpandLess />
            </button>
          )}
          {!examinationMenuOpen && (
            <button onClick={() => setExaminationMenuOpen(true)}>
              <MdExpandMore />
            </button>
          )}
        </p>
        {examinationMenuOpen && (
          <ul className={`text-lg  bg-gray-100 text-gray-700`}>
            {examinationMenu.map((menu, idx) => {
              return (
                <li
                  key={idx}
                  className={`${
                    !sideBarState ? 'text-xl p-2' : ''
                  } p-2 hover:bg-green-100 transition-colors ${
                    location.pathname === menu.link
                      ? 'bg-green-200 text-gray-700 font-semibold border-l-4 border-gray-700 transition-all'
                      : ''
                  }`}
                >
                  <Link
                    to={menu.link}
                    className=" 
                flex items-center justify-start w-full gap-4"
                  >
                    {menu.icon}
                    {sideBarState && menu.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="mt-4">
        <p
          className="text-lg bg-gray-100 text-center py-2 border-t border-gray-200 cursor-pointer"
          onClick={() => setMasterMenuOpen((pre) => !pre)}
        >
          {sideBarState && 'Master Setting'}{' '}
          {masterMenuOpen && (
            <button onClick={() => setMasterMenuOpen(false)}>
              <MdExpandLess />
            </button>
          )}
          {!masterMenuOpen && (
            <button onClick={() => setMasterMenuOpen(true)}>
              <MdExpandMore />
            </button>
          )}
        </p>
        {masterMenuOpen && (
          <ul className={`text-lg  bg-gray-100 text-gray-700`}>
            {masterMenu.map((menu, idx) => {
              return (
                <li
                  key={idx}
                  className={`${
                    !sideBarState ? 'text-xl p-2' : ''
                  } p-2 hover:bg-green-100 transition-colors ${
                    location.pathname === menu.link
                      ? 'bg-green-200 text-gray-700 font-semibold border-l-4 border-gray-700 transition-all'
                      : ''
                  }`}
                >
                  <Link
                    to={menu.link}
                    className=" 
                flex items-center justify-start w-full gap-4"
                  >
                    {menu.icon}
                    {sideBarState && menu.title}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div>
        <p className="text-sm text-center py-2 border-t border-gray-200">
          {sideBarState && 'Saved Reports'}
        </p>
        <ul className="text-base">
          {subMenu.map((menu, idx) => {
            return (
              <li
                key={idx}
                className={`${
                  !sideBarState ? 'text-xl p-2' : ''
                } p-2 hover:bg-green-100 transition-colors ${
                  location.pathname === menu.link
                    ? 'bg-green-200 text-gray-700 font-semibold border-l-4 border-gray-700 transition-all'
                    : ''
                }`}
              >
                <Link
                  to={menu.link}
                  className=" 
                flex items-center justify-start w-full gap-4"
                >
                  {menu.icon}
                  {sideBarState && menu.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
