import { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import EditTransportFS from './editTransportFS';

const ViewTransportFS = ({ transportFS, idx }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  return (
    <tr key={transportFS._id} className="text-left hover:bg-green-50">
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{idx + 1}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{transportFS.route?.route}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{transportFS.feeHead}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{transportFS.amount}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{transportFS.period}</div>
      </td>
      <td className="px-2 whitespace-nowrap text-sm font-medium">
        <div className="">
          <button
            onClick={() => setShowEditModal(true)}
            className="p-2 m-1   rounded-md bg-green-500 hover:bg-green-600 text-white"
          >
            <FaEdit />
          </button>
        </div>
      </td>
      {showEditModal && (
        <EditTransportFS
          transportFS={transportFS}
          handleClick={() => setShowEditModal(false)}
        />
      )}
    </tr>
  );
};

export default ViewTransportFS;
