import { useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import DeleteFeeStructure from 'components/feeStructures/deleteFeeStructure';
import EditFeeStructure from './editFeeStructure';

const ViewFeeStructure = ({ fStruct, idx }) => {
  const [showModel, setShowModel] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <tr key={fStruct._id} className="text-left hover:bg-green-50">
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{idx + 1}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{fStruct._class?._class}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{fStruct.feeHead?.head}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{fStruct.amount}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{fStruct.period}</div>
      </td>
      <td className="px-2 whitespace-nowrap text-sm font-medium">
        <div className="">
          <button
            type="button"
            className="p-2 m-1  cursor-pointer rounded-md bg-green-500 hover:bg-green-600 text-white"
            onClick={() => setShowEditModal(true)}
          >
            <FaEdit />
          </button>
          <button
            desabled
            className="p-2 m-1 pointer-events-none rounded-md bg-red-500 hover:bg-red-600 text-white"
            onClick={() => setShowModel(true)}
          >
            <FaTrash />
          </button>
        </div>
        {showModel && (
          <DeleteFeeStructure
            feeStructure={fStruct}
            handleClick={() => setShowModel(false)}
          />
        )}
      </td>
      {showEditModal && (
        <EditFeeStructure
          feeStructure={fStruct}
          handleClick={() => setShowEditModal(false)}
        />
      )}
    </tr>
  );
};

export default ViewFeeStructure;
