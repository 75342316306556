import { transportFSActionType } from './transportFS.actionTypes';

const INITIAL_STATE = [];

const transportFSReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case transportFSActionType.LOAD_TRANSPORT_FS:
      return action.payload;
    case transportFSActionType.ADD_TRANSPORT_FS:
      return [...state, action.payload];
    case transportFSActionType.UPDATE_TRANSPORT_FS:
      return state.map((tFS) =>
        tFS._id === action.payload._id ? action.payload : tFS
      );

    default:
      return state;
  }
};

export default transportFSReducer;
