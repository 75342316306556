import { feeStructureActionType } from './feeStructure.actionTypes';
import axios from 'axios';

export const addFeeStructure = (fStruct) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_SERVER_URL}feeStructure`,
      data: {
        ...fStruct,
      },
    }).then((res) => {
      dispatch({
        type: feeStructureActionType.ADD_FEE_STRUCTURE,
        payload: res.data.result,
      });
    });
  };
};

export const updateFeeStructure = (fStruct, id) => {
  return (dispatch) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_SERVER_URL}feeStructure/${id}`,
      data: {
        ...fStruct,
      },
    }).then((res) => {
      dispatch({
        type: feeStructureActionType.UPDATE_FEE_STRUCTURE,
        payload: res.data.result,
      });
    });
  };
};

export const removeFeeStructure = (fStruct) => {
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_SERVER_URL}feeStructure/${fStruct._id}`,
    }).then((res) => {
      dispatch({
        type: feeStructureActionType.REMOVE_FEE_STRUCTURE,
        payload: fStruct,
      });
    });
  };
};

export const loadFeeStructure = () => {
  return (dispatch) => {
    axios(`${process.env.REACT_APP_SERVER_URL}feeStructure`).then((res) => {
      dispatch({
        type: feeStructureActionType.LOAD_FEE_STRUCTURE,
        payload: res.data.result,
      });
    });
  };
};
