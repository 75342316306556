import Sidebar from './sidebar';
import AppBar from './appbar';
import { useSelector } from 'react-redux';
const WrapperComponent = ({ children }) => {
  const sideBarState = useSelector((state) => state.sidebar.sideBarState);
  return (
    <div className="">
      <Sidebar />
      <div className={`${sideBarState ? 'ml-64' : 'ml-16'}`}>
        <AppBar />
        {children}
      </div>
    </div>
  );
};

export default WrapperComponent;
