import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addBusRoutes } from 'redux/busRoutes/busRoutes.action';
import Model from 'components/model.component';
const AddBusRotes = ({ handleClick }) => {
  const [busRoute, setBusRoute] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (busRoute !== '') {
      dispatch(addBusRoutes(busRoute));
      handleClick();
      clearForm(e);
    }
  };

  const clearForm = (e) => {
    e.preventDefault();
    setBusRoute('');
  };

  return (
    <Model
      handleClick={handleClick}
      title={'Add Bus Route'}
      className="w-1/2 h-1/3"
    >
      <form className="w-2/3 mt-10 mx-auto text-gray-700 ">
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Bus Route Name:</label>
          <input
            type="text"
            name="busRoute"
            value={busRoute}
            onChange={(e) => setBusRoute(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>

        <div className="flex flex-row mt-2 ">
          <button
            className="w-full m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </button>
          <button
            className="w-full m-5  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-red-900"
            onClick={clearForm}
          >
            Reset
          </button>
        </div>
      </form>
    </Model>
  );
};

export default AddBusRotes;
