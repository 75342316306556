import axios from 'axios';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const PaymentCharts = () => {
  const [feeDb, setFeeDb] = useState([]);

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  useEffect(() => {
    const fecthD = async () => {
      const res = await axios(`${process.env.REACT_APP_SERVER_URL}feestotal`);
      setFeeDb(res.data.result);
    };

    fecthD();
  }, []);

  function random_rgba() {
    var o = Math.round,
      r = Math.random,
      s = 255;
    return (
      'rgba(' +
      o(r() * s) +
      ',' +
      o(r() * s) +
      ',' +
      o(r() * s) +
      ',' +
      r().toFixed(1) +
      ')'
    );
  }
  const rgbaRandoms = feeDb && Object.keys(feeDb).map((ar) => random_rgba());
  const total = feeDb.map((fd) => fd.total);
  const month = feeDb.map((fd) => months[fd._id - 1]);

  const data = {
    labels: [...month],
    datasets: [
      {
        label: '# Of Payments',
        data: [...total],
        backgroundColor: [...rgbaRandoms],
        borderColor: ['black'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="w-1/2 p-2  bg-white shadow-xl rounded-lg ml-1">
      <Bar className="" data={data} />
    </div>
  );
};

export default PaymentCharts;
