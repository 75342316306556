import Model from 'components/model.component';
import { useDispatch } from 'react-redux';
import { removeFeeStructure } from 'redux/feeStructures/feeStructure.action';

const DeleteFeeStructure = ({ feeStructure, handleClick }) => {
  const dispatch = useDispatch();

  return (
    <Model
      handleClick={handleClick}
      title={'Delete Fee Structure'}
      className="max-w-sm"
    >
      <div className="mx-auto w-full text-center p-2 pt-5 pb-14">
        <p>
          Do you want to dalete{' '}
          <span className="font-bold italic">
            {feeStructure.feeHead.head} of {feeStructure._class._class}
            {'?'}
          </span>
        </p>
        <div className="flex flex-row justify-end pr-3 w-full items-center absolute bottom-2 right-0 ">
          <button
            className="p-1 px-5  bg-red-500 rounded-lg text-white hover:bg-red-600"
            onClick={() => {
              dispatch(removeFeeStructure(feeStructure));
              handleClick();
            }}
          >
            Yes
          </button>
          <button
            className="border border-gray-500 rounded-lg p-1 px-5 ml-2 text-gray-500 hover:border-gray-800 hover:text-gray-800"
            onClick={() => {
              handleClick();
            }}
          >
            No
          </button>
        </div>
      </div>
    </Model>
  );
};

export default DeleteFeeStructure;
