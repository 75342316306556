import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { upadateStudent } from 'redux/students/students.action';
import { useLocation, useHistory } from 'react-router-dom';
import Spinner from 'components/spinner';
import { toast } from 'react-toastify';
import SelectSearch, { fuzzySearch } from 'react-select-search';

const EditStudent = () => {
  const location = useLocation();
  const history = useHistory();
  const student = location.state;
  const [loading, setloading] = useState(false);
  const [_classes, set_Classes] = useState([]);
  const [studentsName, setStudentsName] = useState(student.studentsName);
  const [gender, setGender] = useState(student.gender);
  const [fathersName, setFathersName] = useState(student.fathersName);
  const [mothersName, setMothersName] = useState(student.mothersName);
  const [_class, set_Class] = useState(student._class?._id);
  const [dob, setDob] = useState(student.dob);
  const [mobile, setMobile] = useState(student.mobile);
  const [srNo, setSrNo] = useState(student.srNo);
  const [admissionNo, setAdmissionNo] = useState(student.admissionNo);
  const [address, setAddress] = useState(student.address);
  const [transport, setTransport] = useState(student.transport);
  const [route, setRoute] = useState(student.route?._id);
  const [van, setVan] = useState(student.van?._id);

  const { busRoutes } = useSelector((state) => state);
  const vans = useSelector((state) => state.van);

  const busRouteOptions = busRoutes.map((bR) => {
    return { name: bR.route, value: bR._id };
  });

  const vanOptions = vans?.map((van) => {
    return { name: `${van.vanNumber} ${van.driverName}`, value: van._id };
  });

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (_class !== 'Select Class' && gender !== 'Select Gender') {
      setloading(true);
      const res = await axios.put(
        `${process.env.REACT_APP_SERVER_URL}student/${student._id}`,
        {
          studentsName,
          fathersName,
          gender,
          mothersName,
          _class,
          dob,
          mobile,
          srNo,
          admissionNo,
          address,
          transport,
          route,
          van,
        }
      );
      dispatch(upadateStudent(res.data.result));
      setloading(false);
      toast.success('Student Record Updated');
      history.replace('/students');
    }
  };

  useEffect(() => {
    console.log({
      student,
    });
    axios(`${process.env.REACT_APP_SERVER_URL}class`).then((res) =>
      set_Classes(res.data.result)
    );
  }, []);
  return (
    <div className="w-full">
      <div className="w-1/2 mx-auto text-center text-lg font-semibold rounded-lg shadow-sm text-green-900 p-2 m-4 bg-white border-b-2 border-green-900">
        Edit Student
      </div>
      <form className="w-1/2 mx-auto text-gray-700 ">
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Addmission No :</label>
          <input
            type="text"
            name="admissionNo"
            value={admissionNo}
            onChange={(e) => setAdmissionNo(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">SR No :</label>
          <input
            type="text"
            name="srNo"
            value={srNo}
            onChange={(e) => setSrNo(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Student's Name :</label>
          <input
            type="text"
            name="studentsName"
            value={studentsName}
            onChange={(e) => setStudentsName(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Gender :</label>
          <select
            name="gender"
            value={gender}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            onChange={(e) => setGender(e.target.value)}
          >
            <option>Select Gender</option>
            <option>Male</option>
            <option>Female</option>
          </select>
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Father's Name :</label>
          <input
            type="text"
            name="fathersName"
            value={fathersName}
            onChange={(e) => setFathersName(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Mother's Name :</label>
          <input
            type="text"
            name="mothersName"
            value={mothersName}
            onChange={(e) => setMothersName(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Date Of Birth :</label>
          <input
            type="text"
            name="dob"
            placeholder="dd/mm/yyyy"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Mobile No :</label>
          <input
            type="tel"
            name="mobileNo"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>

        <div className="flex flex-row mt-2 items-center">
          <label className="w-1/2">Class :</label>
          <select
            name="_class"
            value={_class}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            onChange={(e) => set_Class(e.target.value)}
          >
            <option>Select Class</option>
            {_classes &&
              _classes.map((cl) => (
                <option key={cl._id} value={cl._id}>
                  {cl._class}
                </option>
              ))}
          </select>
        </div>
        <div className="flex flex-row mt-2">
          <label className="w-1/2">Address :</label>
          <textarea
            type="text"
            name="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className=" border-gray-300  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
          />
        </div>
        <div className="flex relative flex-row mt-2 items-center">
          <label className="w-1/2">Transport* :</label>
          <select
            name="transport"
            value={transport}
            className=" border-gray-300 mb-2  w-full flex-grow rounded-lg border px-3 py-2 shadow-sm focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500"
            onChange={() => setTransport(!transport)}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>
        {transport ? (
          <React.Fragment>
            <div className="flex flex-row mt-2 items-center">
              <label className="w-1/2">Select Van :</label>
              <SelectSearch
                name="van"
                options={vanOptions}
                value={van}
                onChange={setVan}
                search
                filterOptions={fuzzySearch}
                placeholder="Search Van"
              />
            </div>
            <div className="flex flex-row mt-2 items-center">
              <label className="w-1/2">Select Bus Route :</label>
              <SelectSearch
                name="route"
                options={busRouteOptions}
                value={busRouteOptions.find((o) => o.value === route)}
                onChange={setRoute}
                search
                filterOptions={fuzzySearch}
                placeholder="Search route"
              />
            </div>
          </React.Fragment>
        ) : null}
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex flex-row mt-2 ">
            <button
              className="w-full m-5  bg-green-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-green-600 focus:ring-2 focus:ring-green-900"
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </button>
            <button
              className="w-full m-5  bg-red-500 text-lg rounded-lg p-2 shadow-md text-white hover:bg-red-600 focus:ring-2 focus:ring-red-900"
              onClick={() => {
                history.replace('/students');
              }}
            >
              Go Back
            </button>
          </div>
        )}
      </form>
    </div>
  );
};

export default EditStudent;
