import { classActionType } from './class.actionTypes';
import axios from 'axios';
import { toast } from 'react-toastify';

export const addClass = (cls) => {
  return (dispatch) => {
    axios({
      method: 'post',
      url: process.env.REACT_APP_SERVER_URL + 'class',
      data: {
        _class: cls,
      },
    }).then((res) => {
      dispatch({
        type: classActionType.ADD_CLASS,
        payload: res.data.result,
      });
      toast.success('Added Class Sucessfull');
    });
  };
};

export const removeClass = (cls) => {
  return (dispatch) => {
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_SERVER_URL}class/${cls._id}`,
    }).then((res) => {
      dispatch({
        type: classActionType.REMOVE_CLASS,
        payload: cls,
      });
    });
  };
};

export const loadClass = () => {
  return (dispatch) => {
    axios(`${process.env.REACT_APP_SERVER_URL}class`).then((res) => {
      dispatch({
        type: classActionType.LOAD_CLASS,
        payload: res.data.result,
      });
      toast.success('Loaded Class Sucessfull');
    });
  };
};
