import { useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode.react';
import Banner from 'assets/banner.png';
const PrintTransportReciept = () => {
  const location = useLocation();
  const history = useHistory();
  const { student, transportFees, transportFS, payMonths, total } =
    location.state;

  const date = new Date(transportFees.paidDate);
  const [month, day, year] = [
    date.getMonth() + 1,
    date.getDate(),
    date.getFullYear(),
  ];
  const [hour, minutes, seconds] = [
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  const dateAndTime = `${day}-${month}-${year} | ${hour}:${minutes}:${seconds}`;

  const ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
  });
  return (
    <div>
      <h1 className="w-full text-center font-medium font-serif text-2xl text-gray-700 p-5">
        Fee Reciept of {student.studentsName}
      </h1>
      <div className="flex justify-center items-center h-4">
        <button
          onClick={handlePrint}
          className=" p-2 text-gray-700 border border-gray-500  rounded-md hover:bg-gray-50"
        >
          Print Fee Reciept
        </button>
        <button
          onClick={() => history.replace('/')}
          className="ml-5 px-5  p-2 text-red-700 border border-red-500  rounded-md hover:bg-gray-50"
        >
          Close
        </button>
      </div>

      <div
        className="p-5 text-gray-800  flex flex-row flex-wrap w-full justify-between"
        ref={ref}
      >
        {['1', '2'].map((ar) => {
          return (
            <div key={Math.random()} className="border">
              <table className="text-sm" style={{ width: '360px' }}>
                <tr className="border">
                  <td
                    colspan="4"
                    className="bg-gray-100 text-xs text-center font-semibold p-1"
                  >
                    Transport Fee Reciept
                  </td>
                </tr>
                <tr className="border">
                  <td colspan="4" className="">
                    <img
                      src={Banner}
                      alt="sc banner"
                      className="w-72 mx-auto"
                    />
                  </td>
                </tr>
                <tr className="border">
                  <td className="border pl-2">Reciept No:</td>
                  <td className="border">{transportFees.recieptNo}</td>
                  <td className="border pl-2">Date:</td>
                  <td className="border">{dateAndTime}</td>
                </tr>
                <tr className="border">
                  <td className="border pl-2">Admn. No:</td>
                  <td className="border">{student.admissionNo}</td>
                  <td className="border pl-2">Class:</td>
                  <td className="border">{student._class._class}</td>
                </tr>
                <tr className="border">
                  <td className="border pl-2">Name:</td>
                  <td colspan="3">{student.studentsName}</td>
                </tr>
                <tr className="border">
                  <td className="border pl-2">S/D/O:</td>
                  <td colspan="3">{student.fathersName}</td>
                </tr>

                <tr className="border">
                  <td className="border pl-2">Months:</td>
                  <td colspan="3">
                    {transportFees.paidMonths.map((month) => (
                      <span>{month}, </span>
                    ))}
                  </td>
                </tr>
              </table>
              <div className="text-sm" style={{ minHeight: '160px' }}>
                <div className="border py-1  w-full flex flex-row justify-between px-2 bg-gray-100">
                  <p>SN</p>
                  <p className="flex-1 ml-3">Particulars</p>
                  <p>Amount</p>
                </div>
                {transportFS.map((fStruct, idx) => {
                  return (
                    <div className="border w-full flex flex-row justify-between px-2 font-mono">
                      <p>{idx + 1}</p>
                      <p className="ml-5 flex-1">
                        <p className="mb-1">Transport From </p>
                        {fStruct.route.route} <code>(</code>
                        {payMonths[idx].length} X {fStruct.amount}
                        <code>)</code>
                      </p>
                      <p>{total[idx]}</p>
                    </div>
                  );
                })}
              </div>
              <div className="border w-full flex flex-row justify-end text-sm font-medium px-2 bg-gray-100">
                <p className="">Total Fee</p>
                <p className="ml-3">{transportFees.totalFee}</p>
              </div>
              <div className="border w-full flex flex-row justify-between px-2">
                <QRCode
                  value={`${student.studentsName} | Class:${student._class._class} | Reciept No:${transportFees.recieptNo} | Amount Paid:${transportFees.totalFee}`}
                  size={60}
                  className="p-2"
                />
                <div className="flex font-mono text-sm justify-between items-center w-full"></div>
              </div>
              <p className="p-2">
                Recieved By
                <span className="text-xs tracking-widest">
                  ....................................................................
                </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PrintTransportReciept;
