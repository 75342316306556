import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';

import { loadStudent } from 'redux/students/students.action';
let sortArr = [
  'NC',
  'LKG',
  'UKG',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
  'XI- PCM',
  'XI- PCB',
  'XI- COMM',
  'XII- PCM',
  'XII- PCB',
  'XII- COMM',
];

const ClassStrengthCharts = () => {
  const { students } = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    !students.length > 0 && dispatch(loadStudent());
  }, [students.length, dispatch]);

  const res = students.map((std) => std._class._class);

  let counts = {};
  const sampleArray = res;
  sampleArray.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1;
  });

  function random_rgba() {
    var o = Math.round,
      r = Math.random,
      s = 255;
    return (
      'rgba(' +
      o(r() * s) +
      ',' +
      o(r() * s) +
      ',' +
      o(r() * s) +
      ',' +
      r().toFixed(3) +
      ')'
    );
  }
  const rgbaRandoms = counts && Object.keys(counts).map((ar) => random_rgba());

  const data = {
    labels: [...Object.keys(counts)],
    datasets: [
      {
        label: '# Of Students',
        data: [...Object.values(counts)],
        backgroundColor: [...rgbaRandoms],
        borderColor: ['black'],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="w-1/2 p-2 mr-1 bg-white shadow-xl rounded-lg">
      <Bar className="" data={data} />
    </div>
  );
};

export default ClassStrengthCharts;
