import { useState, memo } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import DeleteFeeHead from 'components/feeHeads/deleteFeeHead';

const ViewClass = ({ feeHead, idx }) => {
  const [showModel, setShowModel] = useState(false);

  return (
    <tr key={feeHead._id} className="text-left hover:bg-green-50">
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm font-medium text-gray-900">{idx + 1}</div>
      </td>
      <td className=" px-2 whitespace-nowrap">
        <div className="text-sm  text-gray-900">{feeHead.head}</div>
      </td>
      <td className="px-2 whitespace-nowrap text-sm font-medium">
        <div className="">
          <button className="p-2 m-1 pointer-events-none rounded-md bg-green-500 hover:bg-green-600 text-white">
            <FaEdit />
          </button>

          <button
            className="p-2 m-1 pointer-events-none rounded-md bg-red-500 hover:bg-red-600 text-white"
            onClick={() => setShowModel(true)}
          >
            <FaTrash />
          </button>
        </div>
        {showModel && (
          <DeleteFeeHead
            feeHead={feeHead}
            handleClick={() => setShowModel(false)}
          />
        )}
      </td>
    </tr>
  );
};

export default memo(ViewClass);
